@media screen and (min-width: 800px) {
    .hideOnDesktop{
        display: none !important;
    }
}
@media screen and (max-width: 799px) and (min-width: 300px) {
    .v1--styles{
        .mobile-dnone{
            display: none !important;
        }
        .mobile-flex-column{
            flex-direction: column;

            .w-50, .w-30, .w-67{
                width: 100% !important;
            }
        }
        .mobile-center{
            display: block;
            margin: 0 auto;
        }
        .firstColHero{
            margin-top: 50px !important;
        }
        .activeUsersSection{
            margin-top: 10px !important;
        }
        .mainColHeor{
            margin-top: 100px !important;
        }
        .hideOnMobile{
            display: none !important;
        }
        .mobileFlexWrap{
            flex-wrap: wrap;
        }
        .fullWidthOnMobile{
            width: 100% !important;
        }
        .container{
            padding: 10px 14px !important;
        }
        .guide-content-panel{
            .sdkCardMain{
                flex-direction: column;
                min-width: 310px;
                max-width: 500px;
                width: 98%;

                .categoryBadge{
                    margin-top: 12px !important;
                }
                .imageSection, .detailsSection{
                    width: 100%;
                }
            }
        }

        @for $i from 50 through 100 {
            .fs-#{$i}px {
                font-size: #{$i * 0.55}px;
            }
        }
        @for $i from 10 through 50 {
            .fs-#{$i}px {
                font-size: #{$i * 0.8}px;
            }
        }
        .fs-72px{
            line-height: 46px !important;
        }
        .fs-36px, .fs-56px{
            line-height: 32px !important;
        }
        .fs-26px, .fs-25px{
            line-height: 24px !important;
        }
        .px-81px.py-50px,
        .px-81px.py-40px,
        .px-81px.py-48px,
        .px-81px.py-32px{
            padding-left: 20px !important;
            padding-right: 20px !important;
            padding-top: 14px !important;
            padding-bottom: 20px !important;

        }
        .mobileTextCenter{
            text-align: center;
        }
        .mobileMenuMargin{
            margin: 6px 0;
        }
        .mobile-jcc{
            justify-content: center;
        }
        .mobilePs0{
            padding-left: 0px !important;
        }
        .mobile-my-custom{
            margin-top: 16px !important;
            margin-bottom: 16px !important;
        }
        .py-24px.ps-3{
            padding: 0px !important;
        }
        .eventMainCard.v2{
            max-width: 300px !important;

            img{
                width: 100% !important;
            }

            .eventDescription{
                width: 100% !important;
            }
        }
        .eachLandingCard.Main{
            max-width: 95% !important;
            display: block;
            margin: 8px auto;
        }
        .footerSectionMain{
            .col-md-3{
                margin: 14px 0px !important;
                padding-left: 0px !important;
            }
        }
        .mobile-custom-height{
            height: fit-content !important;
            margin-top: 12px;
        }

        .fw-600.fs-36px.text-content-color.mobile-my-custom,
        .fw-400.fs-25px.text-content-subtle{
            text-align: center;
        }
    }
}

@media screen and (max-width: 1399px) and (min-width: 800px) {
    .v1--styles{

        @for $i from 50 through 100 {
            .fs-#{$i}px {
                font-size: #{$i * 0.75}px;
            }
        }
        @for $i from 10 through 50 {
            .fs-#{$i}px {
                font-size: #{$i * 0.9}px;
            }
        }

        .fs-72px{
            line-height: 56px !important;
        }
        .fs-36px, .fs-56px{
            line-height: 40px !important;
        }
        .fs-26px, .fs-25px{
            line-height: 29px !important;
        }
        .px-81px.py-50px,
        .px-81px.py-48px,
        .px-81px.py-40px,
        .px-81px.py-32px{
            padding-left: 20px !important;
            padding-right: 20px !important;
            padding-top: 14px !important;
            padding-bottom: 14px !important;

        }
        // .mobileTextCenter{
        //     text-align: center;
        // }
        .mobile-my-custom{
            margin-top: 16px !important;
            margin-bottom: 16px !important;
        }
        .py-24px.ps-3{
            padding: 0px !important;
        }
        .eachLandingCard.Main{
            max-width: 400px !important;
            display: block;
            margin: 8px auto;
        }
        .footerSectionMain{
            .col-md-3{
                margin: 14px !important;
            }
        }
        .mobile-custom-height{
            height: 200px !important;
            margin-top: 12px;
        }
        .customNavbar{
            .nav-link{
                font-size: 15px !important;
            }
        }
    }
}

.sidebarToggler{
    display: none;
}
@media screen and (max-width: 991px) {
    .customHero1right{
        flex-direction: column;

        .leftContent{
            width: 100% !important;
        }

        .rightContent{
            img{
                max-height: 280px;
            }
        }
    }

    .eachCardWrapper{
        grid-template-columns: 1fr !important;
    }

    .customImage2{
        flex-direction: column;

        .rightPanel{
            margin-top: 24px !important;
            width: 90% !important;

            text-align: center;

            .titleTagPartner{
                display: block;
                margin: 0 auto;
            }
        }
    }
    
    .v1--styles .centerPanelBetweenLeftRight{
        width: 100% !important;
        &.px-86px{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .sidebarToggler{
        display: block !important;
        position: absolute;
        top: 54px;
        z-index: 9;
    }

    .fixedLeftBar{
        width: 0px !important;
        transition: 0.1s;
        &.pr-32px {padding-right: 0px !important;}
        border-color: transparent !important;

        &.isOpened{
            position: fixed;
            background: var(--base-color) !important;
            z-index: 8 !important;
            width: 97% !important;
            padding-top: 32px !important;
            padding-left: 32px !important;
            border-color: var(--base-200) !important;
        }
    }

    .fixedRightBar{
        display: none !important;
    }

    .darkModeToggle{
      margin-top: 12px !important;
    }
  
    .entireNavbarContainer{
      align-items: start !important;
      padding-left: 10px !important;
    }
    .customNavbar .nav-link,
    .customNavbar .nav-link.show,
    .customNavbarDropdowns{
      padding-left: 0px !important;
      margin-left: 0px !important;
    }
    .darkModeToggle{
      margin-left: 0px !important;
    }
  
    .hideLeftDivInMobile > div:first-child{
        display: none !important;
    }

}

@media screen and (max-width: 429px) {
    .packageCardv1{width: 90vw !important;}
    .guide .guide-card{
        width: 300px !important;
    }
}

@media screen and (min-width: 992px) {
    .mobileMenuMargin{
        margin-left: 1vw !important;
        margin-right: 1vw !important;
    }
}
.theme--dark{
    .navbar-toggler-icon{
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2YyZjJmMiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMjQsMTI4YTgsOCwwLDAsMS04LDhINDBhOCw4LDAsMCwxLDAtMTZIMjE2QTgsOCwwLDAsMSwyMjQsMTI4Wk00MCw3MkgyMTZhOCw4LDAsMCwwLDAtMTZINDBhOCw4LDAsMCwwLDAsMTZaTTIxNiwxODRINDBhOCw4LDAsMCwwLDAsMTZIMjE2YTgsOCwwLDAsMCwwLTE2WiI+PC9wYXRoPjwvc3ZnPg==') !important;
    }
}

.card-alignment{
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 1300px) and (max-width: 1522px) {
        width: 80% !important;
        justify-content: start;
    }
}

.api-reference-container{
    padding-left: 32px;

    .api-reference-list{
        width: 35%;
    }

    .api-reference-desc{
        width: 100%;
    }
    .api-reference-tryout{
        width: 35%;
    }
    
    @media screen and (min-width: 1522px) {
        max-width: 1522px;
        margin: 0 auto;
        width: 100%;
        padding-left: 0;

        .api-reference-tryout-btn, .api-reference-tryout{
            position: absolute;
            top: 10%;
            right: 0%;
        }

        .api-reference-list{
            width: 25%;
        }
    
        .api-reference-desc{
            width: 60%;
        }

        .api-reference-tryout{
            width: 20%;
            height: calc(100vh - 72px);
            overflow: scroll;
        }
    }
}