.__react_component_tooltip.show {
    padding: 4px !important;
    background-color: var(--dark-surface-pure) !important;
    border-radius: 3px !important;
    color: var(--dark-text-100);
    max-width: 250px;
    >span{
        color: var(--dark-text-100);
    }
    >div{
        color: var(--dark-text-100);
    }
}
@for $top from 10 through 50 {
    .__react_component_tooltip.place-top-#{$top}{
        &::after{
            top: #{$top}px !important;
        }
    }
}

.__react_component_tooltip::after{
    background-color: var(--dark-surface-pure) !important;
}

.light{
    .__react_component_tooltip.show {
        background-color: var(--light-surface-pure) !important;
        color: var(--light-text-100);
        >span{
            color: var(--light-text-100);
        }
        >div{
            color: var(--light-text-100);
        }
    }
    
    .__react_component_tooltip::after{
        background-color: var(--light-surface-pure) !important;
    }
    
}