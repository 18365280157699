.gateway-details {
  .chips {
    padding: 4px 6px !important;
    width: max-content;
    background-color: var(--dark-surface-850);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: var(--dark-text-400);
    border-radius: 4px;
  }
}

.customImage2Wrapper{
  .customImage2{
    .leftPanel{
      height: 100%;
      width: fit-content;
      min-width: 45%;
    }

    .rightPanel{
      padding-left: 3%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 45%;
    }
  }
}

.eachCardWrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: fit-content fit-content;
  grid-gap: 10px;
  position: relative;

  &.v2{
    grid-template-columns: 1fr 1fr;
    grid-template-rows: fit-content fit-content fit-content;
  }

  .eachLandingCardv2{
    padding: 32px 48px !important;
    z-index: 4;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.hover-effects{
      border: 0.873896px solid var(--secondary-200);
      box-shadow: 0px 0px 1.16519px rgba(44, 47, 70, 0.2);
      background: var(--base-color);
      border-radius: 8.61487px;
      
      &:hover{
        background: var(--secondary-200);
        animation: zoom-in-zoom-out 1s ease 1;
        animation-fill-mode: forwards;
      }
    }
  }

  .gradientBackgroundAnimation{
    position: absolute;
    background: var(--base-200);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    filter: blur(52px);
    z-index: 2;
  }
}

.testimonialWrapper{
  .testimonialCard{
    overflow: hidden;
    width: 92%;
    
    .quoteStart{
      font-size: 110px;
      font-weight: 700;
      position: absolute;
      color: var(--primary-color);
      top: -32px;
      left: 10px;
    }
    .quoteEnd{
      font-size: 110px;
      font-weight: 700;
      position: absolute;
      color: var(--primary-color);
      bottom: 0px;
      right: 14px;
      transform: rotateX(180deg);
    }


    .imageCircleGrid{
      position: absolute;
      bottom: -25px;
      right: -20px;
      height: 180px;
      width: 180px;
      background: var(--secondary-color);
      border-radius: 100%;

      img{
        position: absolute;
        bottom: 20px;
        right: 0px;
      }
    }
  }
}

.swiper-button-prev, .swiper-button-next{
  color: var(--primary-color) !important;
  background: var(--base-color) !important;
  border-radius: 100%;
  padding: 6px;
  font-weight: 700;
  height: 42px !important;
  width: 42px !important;
  box-shadow: 0px 1.6px 8px 0px rgba(0, 0, 0, 0.2);
}

.slidesToShow1{
  .swiper.swiper-initialized{
    overflow-y: revert;
  }
  .testimonialCard{
    margin-left: 30% !important;
  }
  .swiper-button-prev{
    top: 108%;
    left: 46%;
  }

  .swiper-button-next{
    top: 108%;
    left: 54%;
  }

  .swiper-button-prev, .swiper-button-next{
    background: var(--primary-color) !important;
    color: var(--base-color) !important;
  }
}

.customHero1right{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3%;

  .leftContent{
    width: 50%;
  }
  .rightContent{
    width: fit-content;
  }
}

.customHero1left{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 3%;

  .leftContent{
    width: 52%;
  }
  .rightContent{
    width: 45%;
  }
}

.customHero1center{
  padding-bottom: 0px !important;
}

.text-center-all{
  p{
    text-align: center;
  }
  .mobile-jcc{
    justify-content: center;
  }
}

.overlayImageFromCard{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--theme-200);
  opacity: 0.7;
  img{
    opacity: 0.2;
    object-fit: cover;
  }

  &.v2{
    background: var(--secondary-color) !important;
    opacity: 1;

    img{
      object-fit: cover;
      z-index: 3;
    }

    .rectangleTop{
      height: 100%;
      width: 100%;
      position: relative;
      opacity: 0.699999988079071;
      background: var(--content-color, #FFF);
    }
  }
}

.customImageWrapper{
  .customImage1{

    display: grid;
    grid-template-columns: 1.1fr 1fr;
    grid-template-rows: fit-content fit-content;
    grid-gap: 38px;
    position: relative;

    .leftPanel{

    }

    .rightPanel{
      height: 100%;
    }
  }
}



.customHero2right{
  padding: 4rem 10px;
  width: 100%;
  height: 89vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3%;

  .leftContent{
    width: 67%;
  }
  .rightContent{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: -10px;
    width: fit-content;
    mix-blend-mode: luminosity;
  }
}

.gradientOverlay{
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 72.92%);
  z-index: 2;
}

.primaryCTAButtonv2{
  color: var(--base-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  border-radius: 38px;
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
  padding: 14.599px 29.199px;
}

.secondaryCTAButtonv2{
  color: var(--base-200);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  border-radius: 38px;
  border: 2px solid var(--base-200);
  padding: 14.599px 29.199px;
}