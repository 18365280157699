.card-grid-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(324px, 1fr));

    &.v2{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .card-bg-wrapper{
        padding: 16px;
        .card{
            padding: 16px 16px 8px 16px;
        }
    }

    .card-title{
        font-size: 24px;
    }

    .card-desc{
        font-size: 16px;
    }

    .card-info{
        font-size: 14px;
    }

    @media screen and (max-width: 425px) {
        display: flex ;
        flex-direction: column;
        
        .card-bg-wrapper{
            padding: 12px;
            .card{
                padding: 12px 12px 6px 12px;
            }
        }

        .card-title{
            font-size: 16px;
        }
    
        .card-desc{
            font-size: 12px;
        }
    
        .card-info{
            font-size: 14px;
        }
    }
}

.pie-chart-scrollbar {
    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--dark-surface-875);
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--dark-surface-850);;
        height: 10px;
    }
}

.theme--light{
    .pie-chart-scrollbar {
        /* Track */
        ::-webkit-scrollbar-track {
            background: var(--light-surface-875);
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--light-surface-850);;
        }
    }

    .monetization-table{
    
        @media screen and (max-width: 768px) {
            border: 1px solid var(--light-gray-subtext);
    
            >table{
                >tbody{
                    >tr{
                        >td{
                            border-top: 1px solid var(--light-gray-subtext-subtle);
                        }
    
                    }
                }
            }
        }
    }
}

.monetization-table{
    >table{
        >thead{
            >th{
                flex: 0 0 auto;
                width: 16.66666667%;
                padding: 12px 0 12px 16px;
            }
        }
        >tbody{
            >tr{
                height: 63px;
                >td{
                    padding: 12px 0 12px 16px;
                    height: 100%;
                    flex: 0 0 auto;
                    width: 16.66666667%;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        border-radius: 4px;
        border: 1px solid var(--base-100);
        background: var(--base-color);

        >table{

            >thead{
                >th{
                    padding: 8px 0 8px 16px;
                    min-width: 50%;
                }
            }
            >tbody{
                >tr{
                    >td{
                        padding: 8px 0 8px 16px;
                        border-top: 1px solid var(--base-100);
                        min-width: 50%;
                        box-shadow: 8px 0px 12px 0px rgba(0, 0, 0, 0.12);
                    }

                }
            }
        }
    }
}

.theme--light {
    .react-month-year-picker {
        .react-datepicker {

            .react-datepicker__navigation-icon--previous::before,
            .react-datepicker__navigation-icon--next::before {
                top: 40%;
            }

            .react-datepicker__triangle::before,
            .react-datepicker__triangle::after {
                border-bottom-color: var(--light-surface-consumer-base) !important;
            }

            .react-datepicker__month-container, .react-datepicker__monthPicker {
                background-color: var(--light-surface-consumer-875) !important;
                color: var(--light-text-consumer-300) !important;
            }

            .react-datepicker__header {
                background-color: var(--light-surface-consumer-base) !important;
                color: var(--light-text-consumer-300) !important;
                border-bottom: 1px solid var(--light-strokes-800);
            }

            .react-datepicker__month-text--selected,
            .react-datepicker__month-text--keyboard-selected {
                background: var(--light-surface-consumer-base) !important;
                color: var(--light-text-consumer-300);
            }
            .react-datepicker__month-text:hover {
                background-color: var(--light-surface-consumer-900) !important;
            }
        }
    }
}

.react-month-year-picker {
    .react-datepicker-wrapper{
        height: 100%;
        .react-datepicker__input-container{
            height: 100%;
        }
    }
    .react-datepicker {
        top: 0px !important;
        left: 0px !important;
        border: none !important;
        position: relative !important;
        min-height: max-content;
        color: var(--dark-text-consumer-300) !important;

        .react-datepicker__navigation-icon--previous::before,
        .react-datepicker__navigation-icon--next::before {
            top: 40%;
        }

        .react-datepicker__triangle::before,
        .react-datepicker__triangle::after {
            border-bottom-color: var(--dark-surface-consumer-base) !important;
        }

        .react-datepicker__month-container {
            background: var(--dark-surface-consumer-875);
            color: var(--dark-text-consumer-300);
        }

        .react-datepicker__header {
            background-color: var(--dark-surface-consumer-base) !important;
            color: var(--dark-text-consumer-300) !important;
            border-bottom: 1px solid var(--dark-strokes-800);
        }

        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--keyboard-selected {
            background: var(--dark-surface-consumer-850) !important;
            color: var(--dark-text-consumer-300);
        }
        .react-datepicker__month-text:hover {
            background-color: var(--dark-surface-consumer-850) !important;
        }
    }
}

.theme--light{
    .montize-trend{
        &.trend-up{
            color: var(--light-colors-grass-11);
        }
        &.trend-down{
            color: var(--light-colors-red-11);
        }
        &.trend-middle{
            color: var(--light-colors-blue-11);
        }
    }
}

.montize-trend{
    &.trend-up{
        color: var(--dark-colors-grass-11);
    }
    &.trend-down{
        color: var(--dark-colors-red-11);
    }
    &.trend-middle{
        color: var(--dark-colors-blue-11);
    }
}

.monetize-detail-wrapper{
    .total-list{
        margin-top: 24px;
        gap: 24px;
    }
}

@media screen and (max-width: 768px) {
    .monetize-detail-wrapper{
        padding: 8px;
        border-radius: 16px;
        border: 1px solid var(--base-200);
        background: var(--base-100);
    
        .total-list{
            margin-top: 0;
            gap: 10px;
        }
    }  
}