.userCard{
   width: 32.5%;
}

.bg-gradient-color{
    background: var(--gradient-color);
}

.userCircle{
    width: 34px;
    height: 34px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

// product bundle

.productCard{
    // width: 285px;
    // height: 270px;
    border-radius: 12px;
    // margin-bottom: 20px;
    border: 1px solid var(--base-200);

    &:hover .card-edit-icon{
        opacity: 1 !important;
    }

    .productCardHeader{
        position: relative;
        height: 117px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: var(--gradient-primary);
        overflow: hidden;
        &::-webkit-scrollbar {
            display: none;
        }

        .large-icon{
            position: absolute;
            opacity: 0.4;
            right: 0px;
            top: 0px;
        }

        .edit-delete-icons{
            opacity: 0;
            position: absolute;
            right: 12px;
        }
    }

    &:hover{
        .edit-delete-icons{
            opacity: 1;
        }
    }

    .productCardBody{
        height: 144px;
        position: relative;
        .show-on-hover{
            display: none;
        }
        .small-icon{
            padding: 8px;
            width: 39px;
            height: 38px;
            padding-top: 6px;
            border-radius: 40px;
            position: absolute;
            top: -19px;
            left: 12px;

            svg{
                width: 23px !important;
                height: 22px !important;
            }
        }

        .card-edit-icon{
            width: 20px;
            height: 20px;
            align-items: center;
            justify-content: center;
            border-radius: 40px;
            box-shadow: 1px 1px 24px rgba(0, 0, 0, 0.5);
            position: absolute;
            top: -22px;
            left: 38px;
            display: flex;
            opacity: 0;
        }

        &:hover .show-on-hover{
            display: block;
        }

        .primaryCTAButton {
            background: var(--secondary-200);
            color: var(--content-subtle);
        }
    }
}

.productCardv2{
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--Strokes-Consumer-800, #CAD0DB);
    background: var(--base-color);
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.06);
}

.theme--light .productBundleSection{
    .surface-850{
        background: white;
    }

    .btn-md-v1{
        border-color: var(--primary-color);
    }

    .global-status-tag.approved, .global-status-tag.active{
        background-color: #EBF9EB !important;
        p{
            color: #297C3B !important;
        }
    }
}

.homeModalForLghtMode{
    .modal-header{
        background: var(--base-100);
        border-bottom: 1px solid var(--base-200);
    }
    .modal-body{
        background-color: var(--base-color) !important;
    }
}

.theme--light .homeModalForLightModev2{
    .modal-header{
        background: #F9F9FA;
        border-bottom: 1px solid var(--base-color);
    }
    .modal-body{
        background-color: #EBEDEF !important;
    }
}