.dropdown-menu.form-data{
    --bs-dropdown-min-width : '2rem';
    --bs-dropdown-divider-margin-y : 0;
    a {
        font-size: 12px;
    }
}
.dropdown.sidebar-dropdown{
    .sidebar-dropdown-toggle{
        position: relative;
        background-color: transparent;

        .sidebar-dropdown-icon{
            display: none;
            position: absolute;
            top: 20%;
            right: -2%;
            fill: var(--dark-surface-875);
        }
    }
    .dropdown-menu{
        display: none;
        margin-top: 0;
        width: 230px;
        background: var(--dark-surface-875);
        // position: absolute;
        // inset: auto auto 0px 0px;
        transform: translate3d(217px, 0, 0px) !important;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border: none;
        cursor: pointer;
        padding: 0;
        border-radius: 0;

        .sidebar-dropdown-menu-title{
            color: var(--dark-text-100);
            font-weight: 600;
            font-size: 16px;
            padding: 14px 16px;
        }

        .sidebar-dropdown-menu-item{
            color: var(--dark-text-100);
            font-weight: 500;
            font-size: 14px;
            padding: 12px 16px;
        }

        .sidebar-dropdown-menu:hover{
            background-color: var(--dark-surface-850);
        }

        .sidebar-dropdown-menu-line{
            background-image: linear-gradient(to right, #262626 0%, #b1b1b1 23%, #262626 98%);
            height: 0.5px;
        }
    }
}

.dropdown.sidebar-dropdown:hover{
    .sidebar-dropdown-toggle{

        .sidebar-dropdown-icon{
            display: block;
        }
    }
    .dropdown-menu{
        display: block;
    }
}

.coming-soon, .not-enabled, .no-access-menu {
    padding: 2px 6px;
    border-radius: 11px;
    font-weight: 400;
    font-size: 10px;
}

.coming-soon {
    color: var(--dark-colors-grass-11);
    background-color: var(--dark-surface-900);
}

.not-enabled{
    color: var(--dark-colors-amber-09);
    background-color: var(--dark-surface-900);
}

.no-access-menu{
    color: var(--dark-colors-red-11);
    background-color: var(--dark-surface-900);
}