.forumPageSection {
  min-height: 100vh;
  width: 100%;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  .navbar__container {
    display: flex;
    width: 100%;
    max-width: 1440px;
    padding: 12px 64px;
    justify-content: space-between;
    align-items: center;
    background: var(--base-color);
    backdrop-filter: blur(5.5px);
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;

    @media only screen and (max-width: 1024px) {
      padding: 12px 16px;
    }

    .site-logo {
      max-width: 218px;
      display: flex;
      align-items: baseline;

      img {
        max-height: 50px;
        max-width: 280px;
      }

      .forum-text {
        padding: 4px 8px;
        color: var(--content-color);
        font-size: 12px;
      }
    }

    .auth-btns__container {
      display: flex;
      align-items: center;
      gap: 16px;

      .forum-search-bar {
        display: flex;
        align-items: center;

        &.active {
          flex-direction: row-reverse;
        }

        position: relative;

        .search-suggestion-dropdown {
          width: 100%;
          min-height: 100px;
          max-height: 200px;
          overflow-y: auto;
          background: var(--base-color);
          border-radius: 4px;

          box-shadow: 0px 1px 2px 0px rgba(16, 40, 50, 0.5);
          position: absolute;
          top: 50px;
          left: 0;

          @media only screen and (max-width: 700px){
            height: auto;
            min-height: auto;
            max-height: none;
            box-shadow: none;
            position: relative;
            top: 10px;
            border-radius: 0;
          }

          .suggestion-item {
            cursor: pointer;
            padding: 0.25rem;
            color: var(--content-color);

            &:hover {
              background: var(--base-200);
            }
          }

          .no-results {
            color: var(--content-color);
            width: max-content;
            margin: 0 auto !important;
          }
        }

        .forum-search-input__container {
          display: flex;
          opacity: 0;
          padding: 2px 2px 2px 10px;
          align-items: center;
          gap: 16px;
          border-radius: 4px;
          background: var(--base-color);

          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          gap: 16px;
          transition: 0.5s ease-in-out;

          .mobile-search-overlay__container {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 3;
            background: var(--base-color);
            transform: translateY(-100%);
            transition: 0.5s ease-in-out;
          }
          &.active {
            opacity: 1;
            border: 1px solid var(--secondary-200);

            .forum-search-input {
              flex: 1;
            }


            .mobile-search-overlay__container {
              @media only screen and (max-width: 700px){
                transform: translateY(0);
              }
            }
          }

          .forum-search-input {
            color: var(--content-color);
            font-size: 16px;
            font-weight: 500;
            flex: 0;
            background: transparent;
            border: none;
            outline: 1px solid transparent;
            padding: 0.25rem;
          }

          .search-btn {
            display: flex;
            padding: 8.304px 16px;
            align-items: center;
            gap: 18.685px;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid var(--base-200);
            background: var(--primary-subtle);
            font-size: 12.456px;
            font-style: normal;
            font-weight: 600;
            color: var(--accent-color);
          }

          .close-search-icon {
            cursor: pointer;
          }
        }

        .forum-search-input__container-mobile {
          display: flex;
          flex: 1;
          padding: 10px 14px;
          align-items: center;
          gap: 16px;
          border-radius: 4px;
          background: var(--base-color);

          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          gap: 16px;
        }

        .search-icon {
          cursor: pointer;
        }
      }

      .dev-portal-redirect-btn {
        padding: 8px 16px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--primary-color);
        outline: none;
        border: 1px solid transparent;
        background: transparent;
        display: flex;
        align-items: center;
        gap: 8px;
        width: max-content;
        @media only screen and (max-width: 700px) {
          display: none;
        }
      }

      .darkModeToggle {
        @media only screen and (max-width: 700px) {
          display: none;
        }
      }

      .search-icon {
        background: transparent;
        border: 1px solid transparent;
        outline: none;
        cursor: pointer;
        color: var(--content-color);
      }

      .login-btn {
        display: flex;
        padding: 8.304px 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--base-200);
        font-size: 12.456px;
        font-weight: 600;
        color: var(--primary-color);
        height: max-content;
      }
      .register-btn {
        display: flex;
        padding: 8.304px 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        background: var(--primary-color);
        font-size: 12.456px;
        font-weight: 600;
        color: var(--base-color);
        border: 1px solid transparent;
        height: max-content;
      }

      .hamburger-menu {
        background: transparent;
        border: 1px solid transparent;
        outline: none;
        cursor: pointer;
        display: none;

        @media only screen and (max-width: 1024px) {
          display: block;
        }
      }
    }
  }

  .forum-page-content {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    margin-top: 65px;
    position: relative;
    // max-width: 1440px;
    @media only screen and (max-width: 420px) {
      margin-top: 90px;
    }

    .sidenav__container {
      width: 320px;
      height: calc(100vh - 65px);

      position: absolute;
      top: 0;
      left: 2.5%;
      z-index: 1000;

      display: flex;

      padding: 32px 8px 32px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-right: 1px solid var(--base-200);
      background: var(--base-color);

      /* Extra small devices (phones, 600px and down) */
      @media only screen and (max-width: 600px) {
      }

      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 100%;
        z-index: 4;
        transform: translateX(0);
        height: 100vh;
        width: 100%;
        transition: 0.5s ease-out;

        &.show {
          transform: translateX(-100%);
        }

        .post-creation-btn-cta {
          display: none;
        }
      }

      .sidenav {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: auto;
        padding-bottom: 10px;
        position: relative;

        .mobile-menu-close-btn__container {
          padding: 8px 10px 8px 0px;
          display: none;

          @media only screen and (max-width: 1024px) {
            display: flex;
            justify-content: space-between;
          }

          .mobile-menu-close-btn {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 4.4px;
            background: var(--base-200);
            backdrop-filter: blur(2.75px);
            cursor: pointer;
          }
        }

        .home-menu-item {
          color: var(--primary-color);
          font-size: 16px;
          font-weight: 500;
          line-height: 14px;
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 8px 10px 8px 16px;
          width: 100%;
          cursor: pointer;
        }

        .collapsible-btn {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          cursor: pointer;

          color: var(--primary-color);

          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }

        .category-list__container {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .category-list-item {
            display: flex;
            padding: 8px 14px;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: var(--content-color);
            text-transform: capitalize;
            cursor: pointer;
            transition: 0.25s ease-in-out;
            position: relative;

            &:hover {
              padding: 8px 10px 8px 16px;
              color: var(--primary-color);
              border-left: 1px solid var(--primary-color);
              font-weight: 600;
            }

            &.active {
              padding: 8px 10px 8px 16px;
              color: var(--primary-color);
              border-left: 1px solid var(--primary-color);
              background: var(--secondary-200);
              font-weight: 600;
            }
          }
        }

        .create-category-cta {
          display: flex;
          padding: 8px 14px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          color: var(--primary-color);

          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          cursor: pointer;
        }

        .popular-tags-list__container {
          display: flex;
          flex-wrap: wrap;
          gap: 12px 6px;

          .popular-tag {
            display: flex;
            padding: 4px 8px;
            align-items: center;
            gap: 4px;
            border-radius: 200px;
            background: var(--secondary-200);
            color: var(--content-subtle);
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            cursor: pointer;

            &.selected {
              border: 1px solid var(--primary-color);
            }
          }
        }
      }
    }

    .forum-content__container {
      width: calc(100% - 330px);
      overflow: hidden;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .forum-post-type-tab__container {
        width: 672px;
        margin: 0 auto;
        display: flex;
        margin-bottom: 16px;
        position: absolute;
        top: 0;
        z-index: 1;
        background: var(--base-color);

        .tab {
          min-width: 100px;
          width: max-content;
          padding: 8px 10px;
          color: var(--secondary-100);
          font-size: 16px;
          font-weight: 500;
          line-height: 14px;
          cursor: pointer;
          text-align: center;

          &.active {
            color: var(--primary-color);
            border-bottom: 2px solid var(--primary-color);
          }

          &.sort-btn {
            margin-left: auto;
          }
        }

        @media only screen and (max-width: 1024px) {
          width: 85%;
        }
      }

      @media only screen and (max-width: 1024px) {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .post-edit-delete-dropdown__container {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    .post-edit-delete-dropdown-menu {
      background: var(--base-100);
      transform: translate3d(-7px, 38px, 0px) !important;
    }

    .dropdown-item {
      background-color: transparent;
      color: var(--content-color);

      &:hover {
        background: var(--base-200);
      }
    }
  }
}

.forum-v1--styles {
  .posts__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 672px;
    position: relative;
    height: calc(100vh - 65px);
    overflow-y: auto;
    padding: 0 18px 0 0;
    padding-bottom: 50px;

    @media only screen and (max-width: 1024px) {
      padding-top: 3rem;
    }

    .post-creation-input-cta {
      display: flex;
      padding: 24px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px solid var(--secondary-200);
      background: var(--base-color);
      box-shadow: 0px 4px 18px 2px rgba(0, 0, 0, 0.05);

      @media only screen and (max-width: 1024px) {
        display: none;
      }

      .user-profile {
        width: 48px;
        height: 48px;
        border-radius: 200px;
      }
      .dummy-post-input {
        display: flex;
        padding: 10px 12px;
        flex: 1 0 0;
        border-radius: 8px;
        background: var(--base-100);
        color: var(--secondary-100);
        font-size: 16px;
        font-weight: 500;
        line-height: 14px;
        border: 1px solid transparent;
        outline: none;
        cursor: pointer;
        transition: 0.25s ease-in-out;

        &::placeholder {
          color: var(--secondary-100);
        }

        &:hover {
          border: 1px solid var(--primary-subtle);
          background: var(--base-200);
        }
      }
      .dummy-post-attachments__container {
        display: flex;
        gap: 16px;
        align-items: center;

        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .mobile-post-creation-cta {
      display: flex;
      padding: 12px 24px;
      align-items: center;
      gap: 8px;
      border-radius: 200px;
      background: var(--primary-color);
      box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.1),
        0px 20px 24px -4px rgba(0, 0, 0, 0.16);
      height: 44px;
      position: fixed;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
      color: var(--base-color);
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      z-index: 1;
      cursor: pointer;

      @media only screen and (min-width: 1025px) {
        display: none;
      }
    }

    .post-reply-input-cta {
      display: flex;
      padding: 16px;
      flex-direction: column;
      gap: 16px;
      border-radius: 16px;
      width: 100%;
      border: 1px solid var(--primary-color);

      .user-profile {
        width: 48px;
        height: 48px;
        border-radius: 200px;
      }
      .dummy-post-input {
        display: flex;
        padding: 10px 12px;
        flex: 1 0 0;
        color: var(--secondary-100);
        font-size: 16px;
        font-weight: 500;
        line-height: 14px;
        border: 1px solid transparent;
        outline: none;
        cursor: pointer;
        transition: 0.25s ease-in-out;

        &::placeholder {
          color: var(--secondary-100);
        }

        &:hover {
          border-radius: 8px;
          border: 1px solid var(--primary-subtle);
          background: var(--base-200);
        }
      }
      .dummy-post-attachments__container {
        display: flex;
        gap: 16px;
        align-items: center;

        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .create-post-modal__container {
      width: 672px;
      height: 92vh;
      display: flex;
      padding: 24px;
      flex-direction: column;
      gap: 16px;
      position: fixed;
      bottom: 0px;
      left: calc(320px + (100vw - 320px - 672px) / 2);
      background: var(--base-color);
      transition: 0.5s ease-in-out;
      border-radius: 16px 16px 0px 0px;
      box-shadow: 0px -8px 8px -4px rgba(0, 0, 0, 0.1),
        0px 20px 24px -4px rgba(0, 0, 0, 0.16);
      transform: translateY(100%);
      z-index: 1;

      @media only screen and (max-width: 1024px) {
        width: 90%;
        left: 7.5%;
        height: 90vh;
        transform: translate(-3.5%, 100%);
      }
      @media only screen and (max-width: 600px) {
        height: 100vh;
        width: 100%;
        z-index: 5;
        left: 0;
        transform: translateY(100%);
      }

      &.open {
        transform: translateY(0);
        @media only screen and (min-width: 601px) and (max-width: 1024px) {
          transform: translate(-3.5%, 0);
        }
      }

      .create-post-modal-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--content-subtle);
        font-size: 16px;
        font-weight: 500;
        line-height: 14px;
      }

      .create-post-dropdowns__container {
        display: flex;
        gap: 16px;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
        }
      }

      .create-post-modal-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: var(--content-subtle);
        border: 1px solid transparent;
        outline: none;
        background: transparent;
      }

      .create-post-modal-textarea {
        width: 624px;
        flex: 1;
        height: 200px;
        border-top: 1px solid var(--base-200);
        border-bottom: 1px solid var(--base-200);
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      .create-post-modal-cta__container {
        display: flex;

        .primary-cta {
          display: flex;
          height: 36px;
          padding: 11px 20px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          border: 0.5px solid var(--secondary-200);
          background: var(--primary-color);
          color: var(--base-color);
          font-size: 12px;
          font-weight: 500;
          line-height: 100%;
        }
        .secondary-cta {
          display: flex;
          height: 36px;
          padding: 11px 16px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          color: var(--content-color);
          border: 1px solid transparent;
          background: transparent;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    @media only screen and (max-width: 1024px) {
      width: 85%;
    }

    .post-listing-load-more {
      opacity: 0;
    }
  }

  .post-creation-btn-cta {
    display: flex;
    height: 40px;
    padding: 11px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--primary-color);
    border: 1px solid transparent;
    color: var(--base-color);
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;

    &.lg {
      width: 256px;
    }
  }

  .post-view-card {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--secondary-200);
    background: var(--base-color);
    box-shadow: 0px 4px 18px 2px rgba(0, 0, 0, 0.05);

    .post-meta-tags__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .post-meta-info__container {
        display: flex;
        align-items: center;
        gap: 12px;

        text-align: center;
        font-size: 14px;

        line-height: 14px;
        position: relative;
        width: 100%;

        .placeholder-profile-container {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 40.5px;
          background: var(--secondary-200);
          display: flex;
          justify-content: center;
          align-items: center;

          .placeholder-profile {
            opacity: 0.5;
          }
        }

        .user-profile {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 200px;
        }

        .user-name--creation-time__container {
          display: flex;
          align-items: center;
          gap: 12px;

          @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: start;

            max-width: 178px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .user-name {
          color: var(--content-color);
          font-weight: 600;
        }

        .post-creation-time {
          color: var(--content-subtle);
        }
      }

      .post-tags__container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .tag {
          display: flex;
          padding: 10px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 4px;
          background: var(--secondary-200);
          color: var(--primary-color);
          text-align: center;
          font-size: 14px;
          line-height: 100%;
        }
      }
    }

    .post-content__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .post-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: var(--content-color);
        cursor: pointer;
      }

      .post-content {
        // TODO: need to add the guides styles here
        color: var(--secondary-100);
        font-size: 16px;
        width: 100%;

        .read-more-btn {
          color: var(--primary-color);
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }

    .post-upvotes-reply-count__container {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      .upvote-count,
      .reply-count {
        display: flex;
        align-items: center;
        gap: 8px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
      }

      .upvote-count {
        .count-value {
          color: var(--content-color);
        }
      }

      .reply-count {
        .count-value {
          color: var(--primary-color);
        }
      }
    }
  }

  .post-icon {
    cursor: pointer;
  }
}

.post-category-modal-dialog {
  display: flex;
  width: 520px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--base-color) !important;
  border-radius: 4px;

  .post-category-modal-content {
    border: none !important;
  }

  .post-category-modal-header {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    color: var(--content-color);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    border-bottom: 1px solid var(--base-200);

    .modal-close {
      cursor: pointer;
    }
    .post-category-modal-title {
      flex: 1;
    }
  }

  .post-category-modal-body {
    display: flex;
    padding: 16px 16px 10px 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    .input-group__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .input-label {
        color: var(--content-subtle);
        text-align: center;
        font-size: 14px;
        line-height: 100%;
      }

      .input-box {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--base-200);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        color: var(--content-color);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        outline: none;
      }

      .input-warning {
        color: var(--content-subtle);
        font-size: 12px;
        line-height: 100%;
      }
    }

    .primary-cta {
      display: flex;
      height: 40px;
      padding: 11px 20px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-radius: 4px;
      background: var(--primary-color);
      border: 1px solid transparent;
      outline: none;
      color: var(--base-color);
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
    }
  }
}

.hover-effect-links:hover {
  background: #f8f9fa;
}

.theme--dark .hover-effect-links:hover {
  background: var(--base-200);
}


@media only screen and (max-width: 420px) { 
  .navbar-brand{
    img{
      max-width: 220px !important;
    }
  }
}