.badge {
    border-radius: 20px;
    line-height: 1;
    font-size: rem(10, 1) !important;
    align-self: baseline;

     &.badge-lg {
        min-width: 98px;
        padding: 11px 5px;
        margin-right: 15px;
    }

    &.badge-md {
        font-weight: 500;
        min-width: 82px;
        padding: 6.5px 5px;
        margin: 0;
        font-size: 12px !important;
    }

    &.badge-sm {
        font-weight: 500;
        margin: 0;
        font-size: 10px !important;
        text-transform: uppercase;
        background-color: transparent !important;
        border: none !important;
        padding: 0 !important;
    }

    &.badge-danger {
        border: 1px solid #ec5a46;
        color: #ec5a46;
        background-color: rgba(236,90,70,.13);
    }


    &.badge-success {
        border: 1px solid #36aa47;
        color: #36aa47;
        background-color: rgba(54,170,71,.13);
    }

    &.badge-approved {
        background: #EBF9EB;
        border-radius: 12px;
        color: #297C3B;
        text-transform: capitalize;
    }

    &.badge-pending {
        background: #FFF9ED;
        border-radius: 12px;
        color: #AD5700;
        text-transform: capitalize;
    }

}