body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p{margin: 0px !important; padding: 0px !important;}

:root{
  --navbar-width: 58px;
  --header-height: 56px;
  --navbar-body-gap: 18px;

  --selection-highlight-background: #848586;

  --currentBackground: white;
  --dark-text-50: #fbfbfb;
  --dark-text-100: #f6f6f6;
  --dark-text-200: #f1f1f1;
  --dark-text-300: #e4e4e4;
  --dark-text-400: #c2c2c2;
  --dark-surface-600: #7a7a7a;
  --dark-surface-700: #666;
  --dark-surface-800: #464646;
  --dark-surface-850: #2F3135;
  --dark-surface-875: #292A2F;
  --dark-surface-900: #242529;
  --dark-surface-950: #1d1e23;
  --dark-surface-base: #1D1E23;
  --dark-surface-pure: #000;


  /* Stroke Colors */
  --dark-strokes-600: #353638;
  --dark-strokes-800: #464747;
  --dark-strokes-700: #363943;

  --light-strokes-600: #d8deeb;
  --light-strokes-700: #d8deeb;
  --light-strokes-800: #cad0db;

  --gray-500: #667085;

  --dark-colors-blue-05: #0F3058;
  --dark-colors-blue-09: #0091ff;
  --dark-colors-blue-10: #369EFF;
  --dark-colors-blue-11: #52A9FF;

  --dark-colors-red-05: #541B1F;
  --dark-colors-red-09: #E5484D;
  --dark-colors-red-11: #FF6369;

  --dark-colors-grass-06: #1D4427;
  --dark-colors-grass-09: #46A758;

  --dark-colors-amber-09: #FFB224;

  --dark-colors-crimson-09 : #E93D82;
  --dark-colors-grass-02: #0f1e13;
  --dark-colors-grass-11: #63c174;
  --dark-colors-amber-02: #271700;
  --dark-colors-amber-11: #f1a10d;
  --dark-colors-red-02: #291415;
  --dark-colors-red-11: #ff6369;
  --dark-colors-blue-02: #0F1B2D;

  /* API Methods Bg */
  --dark-colors-grass-06: #1D4427;
  --dark-colors-amber-05: #4A2900;
  --dark-colors-red-05: #541B1F;
  --dark-colors-blue-05: #0F3058;
  --dark-colors-violet-05: #32275F;
  --dark-colors-purple-05: #432155;
  --dark-colors-orange-05: #4F2305;
  
  /* STATUS CODE GROUP BG-COLOR */
  --dark-colors-blue-06: #0D3868;
  --dark-colors-amber-06: #573300;
  --dark-colors-red-06: #671E22;

  --dark-lemonade-base-200: #33382E;


  --light-surface-850: #fcfcfd;

  --light-colors-grass-06: #B7DFBA;
  --light-colors-amber-05: #FFE3A2;
  --light-colors-red-05: #FDD8D8;
  --light-colors-blue-05: #CEE7FE;
  --light-colors-violet-05: #E4DEFC;
  --light-colors-purple-05: #EDDBF9;
  --light-colors-orange-05: #FFDCC3;
  /* API Methods Bg */

  /* STATUS CODE GROUP COLOR */
  --light-colors-blue-11: #006ADC;
  --light-colors-grass-11: #297C3B;
  --light-colors-amber-11: #AD5700;
  --light-colors-red-11: #CD2B31;
  --light-colors-blue-09: #0091ff;


  /* STATUS CODE GROUP BG-COLOR */
  --light-colors-blue-06: #B7D9F8;
  --light-colors-amber-06: #FFD386;
  --light-colors-red-06: #F9C6C6;


  --light-surface-875: #F9FAFA;
  --light-surface-900: #F4F5F5;
  --light-surface-base:#EBEDEF;
  --light-surface-pure: #E6E6E6;
  --light-surface-800: #B8B8B8;
  --light-surface-850: #fcfcfd;
  --light-surface-700: #A3A3A3;
  --light-surface-950: #EBEDEF;
  --light-text-100: #272727;
  --light-text-200: #323232;
  --light-text-400: #666666;
  --light-text-50: #252525;
  --light-surface-600: #8f8f8f;
  --light-text-300: #464646;

  --light-lemonade-base-200: #EBF2E4;
  --light-colors-amber-02: #FFF9ED;
  --light-colors-grass-02: #F3FCF3;
  --light-colors-blue-03: #EDF6FF;
  --light-colors-red-02: #FFF8F8;


  --swiper-navigation-size: 18px !important;
  
  --light-surface-consumer-875: #FAFBFE;
  --light-surface-consumer-850: #FCFCFD;
  --light-surface-consumer-pure: #DADEEA;
  --light-surface-consumer-900: #FFFFFF;
  --light-surface-consumer-base: #EDF0F7;
  --light-strokes-consumer-800: #CAD0DB;
  --light-strokes-consumer-700: #D8DEEB;
  --light-strokes-consumer-600: #DDE6F0;
  --light-text-consumer-50: #252525;
  --light-text-consumer-400: #666666;
  --light-text-consumer-300: #464646;
  --light-text-consumer-200: #323232;
  --light-text-consumer-100: #333333;
  --light-strokes-consumer-400: #666666;
  --light-strokes-consumer-300: #464646;
  --light-strokes-consumer-200: #323232;
  --light-strokes-consumer-100: #333333;
  --light-consumergrey-consumer-grey-subtext: #4E5766;
  --light-consumergrey-consumer-grey-subtext-subtle: #B8B8B84C;

  --dark-surface-consumer-875: #292A2F;
  --dark-surface-consumer-850: #2F3135;
  --dark-surface-consumer-pure: #000000;
  --dark-surface-consumer-900: #242529;
  --dark-surface-consumer-base: #1D1E23;
  --dark-strokes-consumer-800: #3F434E;
  --dark-strokes-consumer-700: #363943;
  --dark-strokes-consumer-600: #31343C;
  --dark-text-consumer-50: #fbfbfb;
  --dark-text-consumer-400: #C2C2C2;
  --dark-text-consumer-300: #E4E4E4;
  --dark-text-consumer-200: #F1F1F1;
  --dark-text-consumer-100: #F6F6F6;
  --dark-strokes-consumer-400: #C2C2C2;
  --dark-strokes-consumer-300: #E4E4E4;
  --dark-strokes-consumer-200: #F1F1F1;
  --dark-strokes-consumer-100: #F6F6F6;
  --dark-consumergrey-consumer-grey-subtext: #778193;
  --dark-consumergrey-consumer-grey-subtext-subtle: #7C77774C;

  /* Gray Colors */
  --dark-gray-subtext: #9098a7;
  --dark-gray-subtext-subtle: #7c7777;

  --light-gray-subtext: #4E5786;
  --light-gray-subtext-subtle: #BBBBBB;
}