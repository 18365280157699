.packageCardv1{
    width: 410px;
    height: 300px;
    border-radius: 16px;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--base-white, #FFF);

    .topSection{
        position: relative;
        height: 35%;
        background: linear-gradient(279deg, #4B73FF 0%, #87F8FF 100%);
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        &.bg-color-0{
            background: linear-gradient(279deg, #4B73FF 0%, #87F8FF 100%);
        }
        &.bg-color-1{
            background: linear-gradient(285deg, #7F74FF 0%, #D9ACFC 100%);
        }
        &.bg-color-2{
            background: linear-gradient(315deg, #5990DC 0%, #ABFAFF 100%);
        }
        &.bg-color-3{
            background: linear-gradient(285deg, #CA4BFF 1.07%, #FFB6F3 99.36%);
        }
        &.bg-color-4{
            background: linear-gradient(279deg, #4B73FF 0%, #87F8FF 100%);
        }
        &.bg-color-5{
            background: linear-gradient(285deg, #FF4BD7 1.07%, #FFB6B6 100%);
        }

        .iconSection{
            position: absolute;
            right: -2px;
        }
    }

    .bottomSection{
        height: 65%;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }
}
.categoryCardv1{
    width: 410px;
    height: 240px;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 32px;

    p{
        text-align: center;
        line-height: 120%;
        z-index: 4;
        position: relative;
        text-shadow: 0px 4px 6px rgba(16, 24, 40, 0.03), 0px 12px 16px rgba(16, 24, 40, 0.15);
    }

    &.bg-color-0{
        background: linear-gradient(285deg, #7F74FF 0%, #D9ACFC 100%);
    }
    &.bg-color-1{
        background: linear-gradient(285deg, #CA4BFF 1.07%, #FFB6F3 99.36%);
    }
    &.bg-color-2{
        background: linear-gradient(285deg, #FF4BD7 1.07%, #FFB6B6 100%);
    }

    .iconplace{
        position: absolute;
        left: 0;
        opacity: 0.3;
        z-index: 1;
    }
}
.theme--dark{
    .packageCardv1{
        border-radius: 16px;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.15);
        border: 1px solid gray;
        background: black;
    }
}

.app-card-wrapper{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 12px; 
        padding: 10px; 
        background:linear-gradient(45deg,red,blue); 
        -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
}

.theme--light{
    .app-badges {
        border-radius: 16px;
        padding: 2px 10px;
        text-transform: capitalize;
        &.Active, &.active{
            border: 1px solid var(--light-colors-grass-09, #46A758);
            background: var(--light-colors-grass-02, #F3FCF3);
            color: var(--light-colors-grass-11, #297C3B);
        }
        &.Pending, &.submitted{
            border: 1px solid var(--light-colors-amber-09, #FFB224);
            background: var(--light-colors-amber-02, #FFF9ED);
            color: var(--light-colors-amber-11, #AD5700);
        }
        &.Revoke, &.revoked, &.revoke, &.Deleted{
            border: 1px solid var(--light-colors-red-09, #e5484d);
            background: var(--light-colors-red-02, #FFF9ED);
            color: var(--light-colors-red-11, #AD5700);
        }
    }

    .productCardMonetization{
        border: 1px solid var(--light-strokes-consumer-800);
    }

    .undefinedChip{
        background: #F8F1EA;
        color: #886349; // brown11
    }
}

.app-badges {
    border-radius: 16px;
    padding: 2px 10px;
    &.Active, &.active{
        border: 1px solid var(--dark-colors-grass-09, #46A758);
        background: var(--dark-colors-grass-02, #F3FCF3);
        color: var(--dark-colors-grass-11, #297C3B);
    }
    &.Pending, &.submitted{
        border: 1px solid var(--dark-colors-amber-09, #FFB224);
        background: var(--dark-colors-amber-02, #FFF9ED);
        color: var(--dark-colors-amber-11, #AD5700);
    }
    &.Revoke, &.revoke, &.revoked, &.Deleted{
        border: 1px solid var(--dark-colors-red-09, #e5484d);
        background: var(--dark-colors-red-02, #FFF9ED);
        color: var(--dark-colors-red-11, #AD5700);
    }
}

.monetize-create-app{
    &.step {
        position: relative;
        &::after{
            content: "";
            position: absolute;
            height: 2px;
            background-color: #CAD0DB;
            top: 18%;
            z-index: 1;
            margin: 0 auto;
            width: 83%;
            left: 58.5%;
        }
    }
}

.middle-line-right
{
    position: relative;
    &::after{
        content: "";
        position: absolute;
        height: 2px;
        width: calc(100% - 36px - 36px);
        left: calc(50% + 36px);
        background-color: #CAD0DB;
        top: 18%;
    }
}


.myAccountCard{
    width: 300px;
    height: 180px;
    cursor: pointer;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid var(--base-200);
}

.hover-box-shadow{
    &:hover{
        box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
    }
}

.filterClass{
    cursor: pointer;
    display: flex;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1.5px solid rgba(184, 184, 184, 0.30);
}

.theme--dark{
    .filterClass{
        border: 1.5px solid #BBBBBB;
    }
}

.productCardMonetization{
    height: 204px;
    width: 400px;
    padding: 16px 24px;
    border-radius: 16px;
    border: 1px solid var(--dark-strokes-consumer-800);
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.06);

    &.active{
        border: 1px solid var(--primary-color);
    }

    &:hover{
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.15);
    }
}

.subscribedPackages{
    &.active {
        border-radius: 4px;
        border: 1px solid var(--primary-color);
        background-color: var(--base-200);
    }
}


.undefinedChip{
    border-radius: 12px;
    padding: 4px 8px 4px 8px;
    background: #2e201a;
    color: #dba06e; // brown11
}

.theme--light{
    .txnHisotry-tabs-container {
        border-bottom: 1px solid var(--light-surface-consumer-850);
        background-color: var(--light-surface-consumer-875);
        color: var(--light-surface-consumer-600);
    }

    .txnHisotry-tab.activeTab {
        padding-bottom: 2px;
        border-bottom: 2px solid var(--light-colors-blue-09);
        color: var(--light-text-consumer-50);
        background-color: var(--light-surface-consumer-900);
    }

    .editor-bg-transparent {
        .monaco-editor {
            background-color: var(--light-surface-consumer-base);
    
            .overflow-guard {
                background-color: var(--light-surface-consumer-base);
                border-radius: 5px;
                border: 1px solid var(--light-surface-consumer-850);
    
                .margin {
                    background-color: var(--light-surface-consumer-base);
                }
    
                .monaco-scrollable-element {
                    background-color: var(--light-surface-consumer-base);
    
                    .monaco-editor-background {
                        background-color: var(--light-surface-consumer-base);
                    }
                }
            }
        }
    }
}

.txnHisotry-tabs-container {
    border-bottom: 1px solid var(--dark-surface-consumer-850);
    font-size: 14px;
    background-color: var(--dark-surface-consumer-875);
    color: var(--dark-surface-consumer-600);
}

.txnHisotry-tab {
    padding: 0.5rem;
    min-width: 100px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
}

.txnHisotry-tab.activeTab {
    padding-bottom: 2px;
    color: var(--dark-text-consumer-50);
    border-bottom: 2px solid var(--dark-colors-blue-09);
    background-color: var(--dark-surface-consumer-900);
}

.editor-bg-transparent {
    .monaco-editor {
        background-color: var(--dark-surface-consumer-base);

        .overflow-guard {
            background-color: var(--dark-surface-consumer-base);
            border-radius: 5px;
            border: 1px solid var(--dark-surface-consumer-850);

            .margin {
                background-color: var(--dark-surface-consumer-base);
            }

            .monaco-scrollable-element {
                background-color: var(--dark-surface-consumer-base);

                .monaco-editor-background {
                    background-color: var(--dark-surface-consumer-base);
                }
            }
        }
    }
}