* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &::selection {
        background: var(--selection-highlight-background);
    }
}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}


body {
    color: var(--content-color);
    * {
        font-family: 'Inter';
        font-style: normal;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}