.productBundleSection {
  .apps-grid-view__container {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    // grid-template-columns: repeat(auto-fit, minmax(312px, 1fr));
    grid-template-columns: repeat(auto-fill, 310px);
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr min-content;
    justify-content: center;
    // align-items: center;
    gap: 20px;

    .productCard {
      max-width: 312px;
      align-self: center;
    }
  }
}

.product-details-modal__container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.312);
  display: flex;
  justify-content: end;
  backdrop-filter: blur(2px);

  .create-app-modal-body,
  .product-details-modal-body {
    width: 577px;
    height: 100vh;
    // overflow-y: auto;
    background: var(--base-color);
    display: flex;
    flex-direction: column;

    .modal-header {
      padding: 0 16px;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--base-200);

      .modal-title {
        width: max-content;
        font-weight: 500;
        font-size: 16px;
        color: var(--content-subtle) !important;
      }

      .modal-action-btns__container {
        display: flex;
        gap: 8px;

        .action-btn {
          font-size: 12px;
          padding: 8px 16px;
          &.primary {
            background: var(--base-200);
            border: 0.5px solid #464646;
            border-radius: 4px;

            color: #464646;
            font-weight: 500;
          }

          &.secondary {
            border-radius: 4px;
            background: transparent;
            font-weight: 400;
            color: #666666;
            border: 1px solid transparent;
          }

          &.close-btn {
            padding: 8px 11.5px;
          }
        }
      }
    }

    .modal-content {
      flex-grow: 1;
      border: none;
      padding: 12px;
      overflow-y: scroll;

      .form__container,
      .product-details-values-card {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .input-group__container {
          display: flex;
          flex-direction: column;
          gap: 9px;

          .label {
            font-weight: 400;
            font-size: 12px;
            color: var(--content-subtle);
            margin: 0;
          }

          .input-value {
            font-weight: 500;
            font-size: 14px;
            color: var(--content-color);
            margin: 0;
          }

          .rate-card {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 8px 12px;
            font-weight: 400;
            font-size: 14px;
            color: var(--content-subtle);

            &:nth-child(odd) {
              background: #ffffff;
            }

            &:nth-child(even) {
              background: #f4f5f5;
            }
          }

          .input-text {
            padding: 12px 16px;
            background: #f9fafa;
            border: 1px solid #b8b8b8;
            border-radius: 4px;
            color: #323232;
            font-weight: 400;
            font-size: 14px;
            outline: none;
          }

          .input-select {
            padding: 12px 16px;
            background: #f9fafa;
            border: 1px solid #b8b8b8;
            border-radius: 4px;

            display: flex;
            align-items: center;

            &__control {
              padding: 0 !important;
              background: #f9fafa;

              border: 1px solid #b8b8b8;
              border-radius: 4px;
            }

            &__value-container {
              padding: 12px 16px !important;
            }

            &__indicator-separator {
              display: none;
            }

            &__dropdown-indicator {
              position: relative;
              height: 100%;
              width: rem(50);

              svg {
                display: none;
              }

              &::before {
                content: "\f9ef";
                font-family: "orion-font";
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
                line-height: 1;
                font-size: 23px;
                color: #000;
              }
            }

            &__control--menu-is-open {
              .input-select__dropdown-indicator {
                &::before {
                  transform: translate(-50%, -50%) rotate(180deg);
                }
              }
            }

            &__menu {
              border-color: 1px solid black !important;
              border-radius: 4px !important;
            }

            &__option {
              color: #030303;
              padding: rem(20) rem(25) !important;
              position: relative;
              padding: 0 !important;
            }

            &__multi-value {
              border-color: rgba(90, 104, 126, 0.2) !important;
              height: 32px;
              border: 1px solid;
              border-radius: 20px !important;
              align-items: center;
              background-color: grey !important;

              &__label {
                color: #030303 !important;
                padding: 0 8px !important;
                font-weight: 600;
                font-size: 0.857rem;
              }
            }

            &__menu {
              background: #e6e6e6;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              color: #fff !important;
              padding: 0px;
              margin: 0px;
              border-radius: 5px !important;
              z-index: 1000 !important;
              padding: 4px;

              &-list {
                padding: 0px;
                margin: 0px;
                max-height: 200px;
                overflow-y: scroll;

                .input-select__option {
                  background: #ffffff;
                  border: 2px solid #e0e0e0;
                  border-radius: 3px;
                  cursor: pointer;

                  .custom-rate-plan-option {
                    width: 100%;
                    height: 100%;
                    padding: 8px 12px;
                    display: flex;

                    .rate-plan-name {
                      width: 200px;

                      .label {
                        font-weight: 400;
                        font-size: 12px;
                        color: #464646;
                        margin-bottom: 9px;

                        .label-value {
                          font-weight: 500;
                          font-size: 14px;
                          color: #323232;
                        }
                      }
                    }

                    .rate-plan-pricings {
                      flex: 1;
                      font-weight: 400;
                      font-size: 14px;
                      color: #464646;
                      display: flex;
                      flex-direction: column;
                      gap: 4px;
                    }
                  }
                }

                .input-select__option--is-selected,
                .input-select__option--is-selected.input-select__option--is-focused {
                  font-weight: 600 !important;
                  background-color: #f4f5f5 !important;
                }

                .input-select__option--is-focused {
                  background-color: #f4f5f5 !important;
                }
              }
            }
          }

          .input-value-chips__container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .value-chips {
              padding: 4px;
              background: #ffffff;
              border-radius: 2px;
              color: #323232;
              font-weight: 500;
              font-size: 12px;
            }
          }
        }

        .attribute-card {
          padding: 18px 16px;
          border: 1px solid rgba(9, 30, 66, 0.13);
          border-left: 5px solid #e4e5e6;

          .header {
            font-weight: 600;
            font-size: 14px;
            color: #191d23;
          }

          .btn-add-attribute {
            display: flex;
            justify-content: center;
            gap: 8px;
            color: #272727;
            font-weight: 500;
            font-size: 14px;
            background: #ebedef;
            padding: 12px 0;
            border: 1px dashed #8f8f8f;
            border-radius: 4px;
            margin: 16px 0 8px 0;
            cursor: pointer;
          }

          .attribute-labels__container {
            width: 100%;
            display: flex;
            gap: 16px;
            margin-bottom: 6px;

            .label {
              width: 224px;
              color: #464646;
              font-weight: 400;
              font-size: 12px;
            }
          }

          .attribute-values__container {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .attribute-value-row {
              display: flex;
              gap: 16px;
              align-items: center;

              .input-text {
                padding: 12px 16px;
                background: #f9fafa;
                border: 1px solid #b8b8b8;
                border-radius: 4px;
                color: #323232;
                font-weight: 400;
                font-size: 14px;
                outline: none;
                width: 224px;
              }

              .delete-attribute {
                width: 32px;
                height: 32px;
                background: #ffffff;

                border: 0.5px solid #464646;
                border-radius: 4px;
                cursor: pointer;

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }

      .product-details-values-card {
        padding: 8px;
        height: max-content;

        .product-guides-link {
          background: var(--base-100);
          padding: 4px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .product-details-oas__container {
          display: flex;
          flex-direction: column;

          .label {
            color: var(--content-subtle);
            font-size: 12px;
            font-weight: 400;
            border-bottom: 1px solid var(--primary-subtle);
          }

          .specs-row__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            border-bottom: 1px solid var(--primary-subtle);
            padding: 8px;

            .spec-name {
              color: var(--content-color);
            }

            .spec-link-btn {
              display: flex;
              align-items: center;
              border: 1px solid var(--primary-subtle);
              background: var(--secondary-200);
              padding: 9px 12px;
              outline: none;
              cursor: pointer;
            }
          }
        }
      }

      .product-swaggers__container {
        .header-container {
          display: flex;
          align-items: center;
          padding: 12px 8px;
          background: var(--primary-subtle);
          /* Light/Strokes/800 */

          border-bottom: 1px solid #b8b8b8;

          .heading {
            width: 50%;

            color: var(--content-subtle);
            font-weight: 400;
            font-size: 12px;
          }
        }

        .product-swaggers-row {
          display: flex;
          align-items: center;

          padding: 16px;

          border-bottom: 1px solid var(--primary-subtle);

          .product-name-container {
            width: 50%;
          }

          .swagger-name-container {
            display: flex;
            width: 35%;

            .names-container {
              width: 75%;
              display: flex;
              flex-wrap: wrap;
              gap: 12px;

              .swagger-name {
                padding: 2px;
                color: var(--content-color);
                font-weight: 500;
                font-size: 14px;
              }
            }
          }

          .docs-link__container {
            width: 15%;

            button {
              background: var(--secondary-200);
              border: 1px solid var(--primary-subtle);
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 9px 12px;
              color: var(--content-color);
            }
          }
        }
      }
    }
  }
}

.external-links__container {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  .external-link {
    width: calc(50% - 16px);
    background: var(--base-100);
    padding: 13px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: var(--content-color);
    cursor: pointer;

    .title {
      margin: 0;
    }
  }
}

.view-purchases-modal__container,
.product-details-modal__container {
  .purchases-header {
    background: #f9fafa;
    padding: 12px 16px;
    border-bottom: 1px solid #b8b8b8;
    color: #464646;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .purchase-cards-container {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .purchase-plan-detail {
      background: var(--base-100);
      padding: 8px 12px;
      color: var(--content-subtle);
      font-weight: 400;
      font-size: 14px;
      text-align: center;

      &:nth-child(even) {
        background: var(--primary-subtle);
      }
    }

    // .purchase-card {
    //   // background: var(--base-100);

    //   .purchase-card-header {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;

    //     .product-bundle-name__container {
    //       display: flex;
    //       align-items: center;
    //       gap: 8px;
    //       .serial_num {
    //         background: #ffffff;
    //         border-radius: 50%;
    //         width: 44px;
    //         height: 44px;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         font-weight: 500;
    //         font-size: 12px;
    //         color: #323232;
    //       }
    //     }
    //   }

    //   .purchase-plans-details__container {
    //     margin-left: 60px;

    //     .purchase-plan-detail {
    //       background: var(--base-100);
    //       padding: 8px 12px;
    //       color: var(--content-subtle);
    //       font-weight: 400;
    //       font-size: 14px;
    //       text-align: center;

    //       &:nth-child(even) {
    //         background: var(--primary-subtle);
    //       }
    //     }
    //   }
    // }
  }

  .wallet-info__container {
    padding: 20px 12px;
    background: #ffffff;

    border: 1px solid #ebedef;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    gap: 8px;
    margin-bottom: 12px;

    .wallet-balance {
      flex-grow: 1;
    }

    .recharge__container {
      display: flex;
      align-items: center;
      gap: 6px;
      .recharge-btn {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        font-weight: 500;
        font-size: 12px;
        color: #464646;
        background: #ffffff;

        border: 0.5px solid #b8b8b8;
        border-radius: 4px;
        outline: none;
      }

      .recharge-input {
        background: #f9fafa;
        /* Light/Strokes/800 */

        border: 1px solid #b8b8b8;
        border-radius: 4px;
        padding: 8px 12px;
        width: 185px;
      }
    }
  }
}

.credential-accordion__container {
  width: 100%;
  height: max-content;
  border: 1px solid var(--base-100);
  border-radius: 4px;

  .credential-accordian-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--base-color);
    padding: 13px 8px;
    border-radius: 4px;
    cursor: pointer;

    .consumer-details {
      font-size: 14px;
      color: var(--content-color);
    }

    .products-count-label {
      background: var(--secondary-color);
      /* Dark/Surface/700 */
      border-radius: 38px;
      padding: 2px 8px;
      font-weight: 400;
      font-size: 12px;
      color: var(--base-color);
    }
  }

  .credential-accordian-contents {
    background: var(--primary-subtle);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .accordian-content-header {
      padding: 12px 8px;
      font-weight: 400;
      font-size: 12px;
      color: var(--content-subtle);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #b8b8b8;

      .products-header {
        width: 55%;
      }

      .status-header {
        width: 45%;
      }
    }

    .accordian-content-rows {
      padding: 12px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;

      &:not(:last-child) {
        border-bottom: 1px solid #b8b8b8;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .product-info {
        width: 55%;
        color: var(--content-color);
      }

      .status-info {
        width: 45%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .action-btn {
          padding: 8px 16px;
          background: #ffffff;

          border: 0.5px solid #b8b8b8;
          border-radius: 4px;
          font-weight: 500;
          font-size: 12px;
          color: #464646;
          text-align: center;
          outline: none;
          width: 74px;
        }
      }
    }
  }
}

.apps-row-action-btn {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 0.5px solid var(--base-200);
  background: var(--secondary-200);
}

.products-tab-switch__container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--base-200);
  position: relative;
  justify-content: space-between;

  .search-box-products__container {
    margin-left: auto;
  }

  .tab {
    width: max-content;
    padding: 8px;
    font-size: 18px;
    font-weight: 500;
    color: var(--content-color);
    cursor: pointer;

    &.active {
      color: var(--primary-color);
      border-bottom: 2px solid var(--primary-color);
    }
  }
}

.tag-product-bundle-option {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 45px;
  border-radius: 38px;
  background: var(--secondary-color) !important;

  color: var(--base-color);
  font-size: 12px;
  font-weight: 400;
}

.search-box-products__container {
  border-radius: 4px;
  border: 1px solid var(--base-200);
  background: var(--base-100);
  width: 279px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 12px 8px;

  input {
    background: transparent;
    outline: none;
    border: 1px solid transparent;
    color: var(--content-color);
  }
}
