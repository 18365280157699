@keyframes navbar-animate {
  0%{
    transform: translateX(-100px);
  }
  100%{
    transform: translateX(0)
  }
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes circle{
  0%{
      transform:rotate(0deg) translate(-210px) rotate(0deg);
  }
  100%{
      transform:rotate(360deg) translate(-210px) rotate(-360deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.02, 1.02);
  }
}

@keyframes twinkleFrames {
  from {
      background-position: 0 0;
  }

  to {
      background-position: -10000px 5000px;
  }
}

@keyframes twinkleFrames2 {
  from {
      background-position: 0 0;
  }

  to {
      background-position: 10000px 5000px;
  }
}

@keyframes cloudsFrames {
  from {
      background-position: 0 0;
  }

  to {
      background-position: 10000px 0;
  }
}

@keyframes slideAnim {
  from {
      transform: translateX(0%);
  }
  to {
      transform: translateX(-100%);
  }
}