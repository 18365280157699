.Evening-time{
    position: relative;

    .animationContainer{
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.6s;
        z-index: 4;
        transition-timing-function: linear;
        border-radius: 4px;
    }

    &:hover{
        .animationContainer{
            opacity: .25;
        }

        .dayNightBomma{
            height: 164px !important;
        }
    }

    .stars,
    .twinkleMask,
    .twinkleMask2,
    .clouds {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
    }
    
    .stars {
        background: #000 url('https://wolf.drjm.co.uk/nightsky/stars.jpg') repeat top center;
        z-index: 0;
    }
    
    .twinkleMask {
        background: transparent url('https://wolf.drjm.co.uk/nightsky/twinklemask.png') repeat top center;
        z-index: 1;
        animation: twinkleFrames 100s linear infinite;
    }
    
    .twinkleMask2 {
        background: transparent url('https://wolf.drjm.co.uk/nightsky/twinkleMask2.png') repeat top center;
        z-index: 2;
        height: 100%;
        animation: twinkleFrames2 300s linear infinite;
    }
    
    .clouds {
        background: transparent url('https://wolf.drjm.co.uk/nightsky/clouds.png') repeat top center;
        z-index: 3;
        height: 100%;
        animation: cloudsFrames 300s linear infinite;
    }
}

.Afternoon-time{

    .afternoonAnimation{
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.6s;
        z-index: 3;
        transition-timing-function: linear;
        border-radius: 4px;
        background: linear-gradient(to bottom, #007ced 1%,#cce7ff 100%);;
    }

    &:hover{
        .afternoonAnimation{
            opacity: 0.3;
        }

        .dayNightBomma{
            height: 164px !important;
        }
    }
}

.Morning-time{

    .morningAnimation{
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.6s;
        z-index: 3;
        transition-timing-function: linear;
        border-radius: 4px;
        background-image: linear-gradient(to right, #00b4db, #0083b0);
    }

    &:hover{
        .morningAnimation{
            opacity: 0.3;
        }

        .dayNightBomma{
            height: 164px !important;
        }
    }
}

.dayNightBomma{
    height: 160px;
    width: 100px;
    position: absolute;
    bottom: 20px;
    z-index: 6;
    right: 130px;
    transition: height 0.6s;

    svg{
        height: 100%;
    }
}