.custom-modal-v1{
    .modal-header{
        background: var(--dark-surface-875);
    }
    .modal-content .modal-body, .modal-footer{
        background-color: var(--dark-surface-900);
    }
    .modal-content, .modal-header, .modal-footer{
        border: none;
    }

    .btn-md-v1{
        background: var(--dark-surface-850);
        border: 0.5px solid var(--dark-surface-800);
        border-radius: 4px;
        padding: 6px 10px;
    }
}

.vertical-stretch-modal {
    .modal-dialog {
        height: calc(100% - 3.5rem);

        .modal-content {
            height: 100%;

            .modal-body {
                padding: 0;
            }
        }
    }
}

.vertical-stretched-vh-modal{
    .modal-dialog {
        height: calc(100vh - 3.5rem);

        .modal-content {
            height: 100%;

            .modal-body {
                padding: 0;
            }
        }
    }
}

.vertical-centered-modal {
    .modal-dialog {
        height: calc(100% - 3.5rem);
        display: flex;
        align-items: center;

        .modal-content {
            .modal-body {
                padding: 0;
            }
        }
    }
}