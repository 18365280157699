.page-404__container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .not-found__top-right {
    position: absolute;
    top: 0;
    right: 0;
  }

  .not-found__bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 325px;

    .title {
      color: var(--primary-color);
      font-size: 22px;
      font-weight: 700;
    }

    .desc {
      color: var(--content-subtle);
      font-size: 12px;
      font-weight: 400;
      text-align: center;
    }

    .cta-btn {
      width: 70.007px;
      height: 17.224px;
      flex-shrink: 0;
      background: var(--primary-color);
      color: var(--base-color);
      font-weight: 700;
      font-size: 9px;
      border: 1px solid transparent;
      border-radius: 10px;
    
    }
  }
}
