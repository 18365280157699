@import "../utils/variables.scss";

.bg-1{
    background: var(--primary-color);
}
.bg-2{
    background: var(--secondary-color);
}
.bg-3{
    background: var(--accent-color);
}

.sidebar {
  width: var(--navbar-width);
  height: 100vh;
  background-color: var(--dark-surface-875);
  padding: 0 !important;

  animation: navbar-animate 0.8s ease-in-out;
}

.disabled-link {
  cursor: not-allowed;
}

.not-allowed {
  cursor: not-allowed;
}

.compass-logo {
  padding: 12px;
  width: 56px;
  height: 56px;
  padding-left : 8px;
}

.navItems-wrapper {
  width: 56px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;

  .navItems-icon {
    path,
    line,
    polygon,
    polyline,
    circle {
      stroke: var(--dark-text-400);
    }
    mask-type: alpha;
    
  }

  .navItems-active {
    display: none;
    position: absolute;
    background: radial-gradient(
      130.56% 65.28% at -30.56% 50%,
      #00b4fe 0%,
      rgba(0, 174, 254, 0.932292) 0.01%,
      rgba(0, 90, 249, 0) 100%
    );
    top: 0;
    left: 0;
    height: 48px;
    width: 15px;
  }

  &.active {
    background-color: var(--dark-surface-900);

    .navItems-icon {
      path,
      line,
      polygon,
      polyline,
      circle {
        stroke: var(--dark-colors-blue-10);
      }
    }

    .navItems-active {
      display: block;
    }
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

@media only screen and (max-height: 720px) {
  .compass-logo{
    width: 48px;
    height: 48px;
    padding-left : 12px;

    >img{
      width: 28px;
      height: 28px;
    }
  }

  .navItems-icon {
    height: 24px;
  }

  .navItems-wrapper {
    height: 36px;
    .navItems-active {
      height: 36px;
    }
  }
}

@media only screen and (min-height: 721px) and (max-height: 810px) {

  .navItems-icon {
    height: 26px;
  }

  .navItems-wrapper {
    height: 42px;
    .navItems-active {
      height: 42px;
    }
  }
}

.sidebar-tab {
  transition: 0.25s ease-in;
  .btn{
    border: none !important;
  }
}

.sidebar-tab:hover {
  background-color: var(--dark-surface-900);
}

.sidebar-dropdown-toggle {
  &::after{display: none !important;}
}

.profileDropdown{
  top: 40px;
  right: 0px;
}

.notificationBadge{
  position: absolute;
  top: -6px;
  right: -10px;
}


// landing page navbar styles
.customNavbar{
  position: fixed;
  width: 100vw;
  z-index: 10;
  &.navbar{
    background: var(--base-color);
  }

  .dropdown-item.active, .dropdown-item:active{
    background: var(--base-200);
  }

  .customNavbarDropdowns{
    .dropdown-menu a {
      font-size: .8rem !important;
    }
  }

  .nav-link, .nav-link.show{
    color: var(--content-color);
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .dropdown-toggle::after{
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iIzI1MjUyNSIgdmlld0JveD0iMCAwIDI1NiAyNTYiIGRhdGEtZGFya3JlYWRlci1pbmxpbmUtZmlsbD0iIiBzdHlsZT0iLS1kYXJrcmVhZGVyLWlubGluZS1maWxsOiMxYzFlMWY7Ij48cGF0aCBkPSJNMjEzLjY2LDEwMS42NmwtODAsODBhOCw4LDAsMCwxLTExLjMyLDBsLTgwLTgwQTgsOCwwLDAsMSw1My42Niw5MC4zNEwxMjgsMTY0LjY5bDc0LjM0LTc0LjM1YTgsOCwwLDAsMSwxMS4zMiwxMS4zMloiPjwvcGF0aD48L3N2Zz4=');
    border: 0px !important;
    position: absolute;
    top: 11px;
  }

  .nav-item.dropdown{
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.theme--dark{
  .customNavbarDropdowns .dropdown-toggle::after{
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iI2ZhZmFmYSIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTMuNjYsMTAxLjY2bC04MCw4MGE4LDgsMCwwLDEtMTEuMzIsMGwtODAtODBBOCw4LDAsMCwxLDUzLjY2LDkwLjM0TDEyOCwxNjQuNjlsNzQuMzQtNzQuMzVhOCw4LDAsMCwxLDExLjMyLDExLjMyWiI+PC9wYXRoPjwvc3ZnPg==') !important;
  }
  .customNavbarDropdowns{
    .dropdown-menu{
      background: var(--base-100);
      border: 1px solid var(--primary-subtle) !important;
      a {
        color: white !important;
        width: 100%;

        &:hover{
          background: var(--base-200);
        }
      }
    }
  }
}