.show-caret-top {
    top: 6px !important;
}

.show-caret-top::before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0L13.9282 12H0.0717969L7 0Z' fill='%231D1E23'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: -10px;
    right: 1px;
    height: 14px;
    width: 14px;
    z-index: -1;
}


.my-bootstrap-dropdown-menu {
    // --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 0;
    // --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0;
    // --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 14px;
    --bs-dropdown-color: var(--dark-text-50);
    --bs-dropdown-bg: var(--dark-surface-base);
    --bs-dropdown-border-color: transparent;
    --bs-dropdown-border-radius: 4px;
    --bs-dropdown-border-width: 0;
    --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
    --bs-dropdown-divider-bg: transparent;
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-dropdown-link-color: var(--dark-text-50);
    --bs-dropdown-link-hover-color: var(--dark-text-50);
    --bs-dropdown-link-hover-bg: var(--dark-surface-875);
    --bs-dropdown-link-active-color: var(--dark-text-50);
    --bs-dropdown-link-active-bg: var(--dark-surface-875);
    --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 6px;
}

.my-bootstrap-dropdown-menu[x-placement="bottom-end"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}


.my-bootstrap-dropdown-menu[x-placement="top-end"] {
    top: calc(-100% - 12px) !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.my-bootstrap-dropdown-item {
    display: flex;
    align-items: center;
    gap: 6px;
}

.my-bootstrap-dropdown-menu[x-placement="bottom-end"] {
    .my-bootstrap-dropdown-item {

        &:first-child,
        &:first-child:hover,
        &.active:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &:last-child:hover,
        &.active:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.my-bootstrap-dropdown-menu[x-placement="top-end"] {
    .my-bootstrap-dropdown-item {

        &:first-child,
        &:first-child:hover,
        &.active:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child:hover,
        &.active:last-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}


.item-align-center {
    .my-bootstrap-dropdown-item {
        justify-content: center;
    }
}

.theme-specific {
    .my-bootstrap-dropdown-menu {
        --bs-dropdown-min-width: calc(100% + 6px);
        --bs-dropdown-bg: var(--base-100);
        --bs-dropdown-color: var(--content-color);
        --bs-dropdown-link-color: var(--content-color);
        --bs-dropdown-link-hover-color: var(--content-color);
        --bs-dropdown-link-active-color: var(--content-color);
        --bs-dropdown-link-hover-bg: var(--base-200);
        --bs-dropdown-link-active-bg: var(--base-200);
        left: -6px !important;
    }

    .my-bootstrap-dropdown-item {
        width: 100%;
    }

    &.manage-variables {
        .my-bootstrap-dropdown-menu {
            --bs-dropdown-min-width: calc(100%);
            left: 0px !important;
        }
    }
}