.left-list-item {
    display: grid;
    column-gap: 8px;
    grid-template-columns: calc(100% - 8px - 80px) 80px;

    &:hover {
        .summary {
            color: var(--primary-color);
        }
    }

    &.active {
        background: var(--secondary-200);
        border-radius: 4px;
        border-left: 1px solid var(--secondary-color);

        .summary {
            color: var(--primary-color);
            font-weight: 600;
        }
    }
}

.param-card {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background: var(--primary-color);
        left: 18px;
    }

    &.in-tryout:last-child {
        &::before {
            height: 12px;
        }
    }

    .param-card-label {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            height: 7px;
            width: 7px;
            border-radius: 50%;
            background: var(--accent-color);
            left: -16px;
            top: 6px;
        }
    }

    &.tryout-response{
        &::before{
            left: -16px;
        }
        &:last-child {
            &::before {
                height: calc(50%);
            }
        }

        .param-card-label{
            &::before{
                left: -19px;
                top: calc(50% - 3px);
            }
        }
    }

}

.body-param-row {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 16px;
        height: 100%;
        width: 1px;
        background: var(--base-200);
    }




    >.body-param-card {
        position: relative;

        &:hover {
            background-color: var(--primary-subtle);
        }

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background: var(--primary-color);
            left: 16px;
        }

        /*
        &.has-children.expanded::before{
            height: calc(100% - 16px);
            top: 0;
        }
        */

        .body-param-card-label {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background: var(--accent-color);
                left: -18px;
                top: 8px;
            }
        }

        &.has-children {
            .body-param-card-label {
                &::before {
                    top: 4px;
                    height: 12px;
                    width: 12px;
                    left: -21px;
                    background: var(--primary-color);
                    background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' stroke-width='0' viewBox='2 1 16 16' aria-hidden='true' height='11px' width='13px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
                }
            }

            &.expanded {
                .body-param-card-label {
                    &::before {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .body-param-info {
            opacity: 0;
            margin-right: 8px;
        }

        &:hover {
            .body-param-info {
                opacity: 1;
            }
        }


    }


    &.first-child {
        &::before {
            height: calc(100% - 28px);
            top: 28px;
        }

        >.body-param-card {
            &::before {
                height: calc(100% - 28px);
                top: 28px;
            }
        }
    }

    &.last-child {
        &::before {
            height: 28px;
            top: 0;
        }

        >.body-param-card.has-children:not(.expanded),
        >.body-param-card:not(.has-children) {
            &::before {
                height: 28px;
                top: 0;
            }
        }
    }

    &.only-child {
        >.body-param-card {
            &::before {
                height: calc(100% - 28px);
                top: 28px;
            }
        }

        >.body-param-card:not(.expanded) {
            &::before {
                width: 0;
            }
        }
    }

    .body-param-card.has-children.expanded {
        &::after {
            content: "";
            position: absolute;
            top: 56px;
            left: 16px;
            height: 28px;
            width: 16px;
            border-left: 1px solid var(--primary-color);
            border-bottom: 1px solid var(--primary-color);
            border-right: 0;
            border-top: 0;
            border-style: dashed;
            border-bottom-left-radius: 6px;
        }
    }
}

.body-param-info-panel {
    position: fixed;
    width: 400px;
    height: 154px;
    background-color: var(--base-color);
    border: 1px solid var(--base-100);
    z-index: 3;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    color: var(--base-color);

    &::-webkit-scrollbar {
        display: none;
    }

    >.panel-tip {

        position: absolute;

        &.bottom {
            top: -19px;
            left: 16px;
        }

        &.top {
            bottom: -17px;
            right: 16px;
        }

    }

    >.panel-body {
        >.panel-body-field {
            display: grid;
            grid-template-columns: 90px calc(100% - 90px);
            column-gap: 8px;
        }
    }
}


.response-status-card {
    .expand-action {
        display: none;
    }

    &:hover {
        background: var(--primary-subtle);

        .expand-action {
            display: block;
        }
    }
}



.settings-input {
    background: var(--base-color);
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid var(--primary-subtle);

    >.settings-input-label {
        background: var(--base-100);
        display: flex;
        justify-content: start;
        padding-left: 12px;
        align-items: center;
        width: 30%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        color: var(--content-subtle);
        font-weight: 500;
    }

    .settings-input-value {
        outline: none;
        border: none;
        background: transparent;
        padding-left: 8px;
        width: calc(70% - 8px);
    }
}


.param-body-example {
    .monaco-editor {
        border-radius: 4px;

        .overflow-guard {
            border: 2px solid var(--secondary-200);
            border-radius: 4px;

            // .monaco-scrollable-element{
            //     canvas{
            //         display: none !important;
            //     }
            // }
        }
    }
}

.code-view-editor {

    .monaco-editor {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        .overflow-guard {
            // border: 2px solid var(--primary-subtle);
            // border-top: 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.settings-gear {
    position: relative;

    .icon-badge {
        position: absolute;

        &.check {

            top: -10px;
            right: -4px;
        }

        &.danger {
            top: 0;
            right: 0;
        }
    }
}

.swagger-info-link:hover{
    border-bottom: 1px solid var(--content-subtle);
    position: relative;
}

.swagger-info-dot{
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--content-subtle);
}

.swagger-info-option-description {
    font-size: 0.8rem;
    color: var(--dark-text-400);
}
  
.swagger-overview-info-icon {
background-color: var(--dark-surface-850);
width: 3.8rem;
height: 3.8rem;
}

.swagger-no-review-alert {
background-color: transparent;
border-radius: 4px;
border: 1px solid #426893;
padding: 0.75rem;
margin-top: 1rem;
font-size: 0.9rem;
}
.swagger-add-review-btn {
padding: 0.5rem;
font-size: 0.8rem;
font-weight: 600;
}

.rating-modal-comment__container > textarea {
height: 8rem;
padding: 1.3rem;
border-radius: 8px;
outline: none;
background-color: var(--dark-surface-900);
color: var(--dark-text-100);
font-size: 1rem;
}

.rating-post-btn-disabled {
cursor: not-allowed !important;
opacity: 0.5;
}

.review-modal-input {
height: 8rem;
padding: 1.3rem;
border-radius: 8px;
outline: none;
background-color: var(--sark-surface-900);
color: var(--dark-text-100);
font-size: 1rem;
}

.specification-modal-download-btn {
position: absolute;
bottom: 2rem;
right: 2rem;
}

.specification-modal-dialog {
width: 80vw !important;
right: 0;
transform: translate(0, 50px) !important;
}

.swagger-code-generator-step-icon {
height: 1.5rem;
width: 1.5rem;
background-color: #0074fb;
color: #fff;
margin-right: 1rem;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
}

.swagger-code-generator-button__container > .btn {
width: 6.2rem;
padding: 0.5rem;
}

.swagger-code-generator-modal-body {
font-size: 0.8rem !important;
}

.swagger-code-generator-step-heading {
font-size: 1rem;
}

.swagger-code-generator-step-heading.inactive {
opacity: 0.5;
}

.Changelog-modal-timeline__container {
padding: 2rem;
border-bottom: 1px solid grey;
// border: 1px solid red;
}

.change-log-duration__container {
display: flex;
align-items: center;
}

.change-log-duration__container > .form-check > label {
margin-top: 0.25rem;
margin-right: 0.5rem;
}

.changelog-calendar-container {
width: max-content;
position: absolute !important;
right: 1rem !important;
top: 3rem !important;
z-index: 1;
}

.commitHistoryInnerWrapper::before {
content: "";
position: absolute;
left: 95px;
top: 0;
border-left: 1px solid rgba(158, 163, 175, 0.2) !important;
height: calc(100%);
}

.itx-comment-wrapper:first-child {
padding-top: 2rem;
}

.itx-comment-wrapper:not(:last-child) {
margin-bottom: 1rem;
}

.itx-comment-wrapper:hover .dateActiveIcon {
background-color: #0074fb;
}

.itx-comment-wrapper:hover .dateActiveIcon:before {
background-color: #0074fb;
}

.commentsIcons {
opacity: 0;
gap: 0.5rem;
}
.commentIcon {
background-color: rgba(90, 104, 126, 0.08) !important;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
width: 29px;
height: 29px;
}

.itx-comment-details-wrapper > .itx-comment-details:hover .commentsIcons {
opacity: 1;
}

.badge-warning {
color: #f2ad2c;
background-color: rgba(242, 173, 44, 0.12);
border: 1px solid #f2ad2c;
}

.changelog-markdown {
    h1 {
        font-size: 1.143rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }
    h4 {
        font-size: 1rem;
        font-weight: 500;
        opacity: 0.6;
        line-height: 1.79;
    }
    h2 {
        font-size: 1.286rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
    }

    h3 {
        font-size: 1.286rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    p {
        margin-bottom: 1.5rem;
    }

    hr {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem
    }

    h1 + hr {
        display: none;
    }

    ul {
        list-style-type: none;
        padding-left: 0
    }

    pre {
        code {
        width: 100%;
        display: block;
        overflow: auto;
        padding: 0.858rem 0;
        background: var(--dark-surface-850);
        }
    }
}



.dateActiveIcon {
    right: 1px;
    color: rgba(8, 8, 8, 0.6);
    top: 36px;
    width: 8px;
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 50%;
}

.dateActiveIcon::before {
content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 0.3;
    background-color: #e5e5e5;
}




.custom-rating-text {
    font-size: 14px;
    color: #878787;
    margin: 0;
    margin-left: 8px;
}

.swagger-reviews__container {
width: 100%;
margin-top: 2rem;
display: flex;
}

.swagger-user-reviews-card__container {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.swagger-review-card {
    width: 45%;
    background-color: var(--dark-surface-875);
    box-shadow: 0px 0px 13px 0px rgb(255 255 255 / 20%);

    .card-header {
        position: relative;
        width: 100%;

        .review-dropdown {
        position: absolute;
        right: 1rem;
        top: 1rem;
        }
    }
}

.swagger-review-card:hover {
    border: 1px solid #0074fb !important;
    box-shadow: 0 4px 32px 0 rgb(0 116 251 / 19%) !important;
}

    [data-initials]:before {
    background: #099bdd;
    color: white;
    opacity: 1;
    content: attr(data-initials);
    display: inline-block;
    font-weight: 500;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.5em;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.swagger-review-card-user {
    font-size: 1rem;
}


.api-catalog-details{
.leftSidebarHeading {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    padding-left: 1.643rem;
}

.op-list-tag{
    padding: 1.43rem 1.429rem 0.577rem;
    font-size: .859rem;
    font-weight: 400;
    line-height: normal;
    opacity: .6;
}

.op-list-summary{
    padding: 1.11rem 1.43rem;
    cursor: pointer;
}

.op-list-summary-text{
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
}

.tryOutBtn {
    position: fixed;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    right: -13px;
    
    a.btn:not(.btn-icon):not(.dropdown-toggle) {
    padding: 0.5rem 2rem !important;
    font-weight: 500 !important;
    font-size: 0.858rem !important;
    border-radius: 4px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    }
}

.border-bottom-group:last-child{
    border-bottom: none;
}

.overviewDetailsWrapper{
    padding: 1.21rem 1.43rem !important;
    display: flex;
}

.overviewDetailsType{
    padding-right: 7.357rem;
    .tag-name{
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 0.714rem;
    }
    .summary-details{
        font-size: .859rem;
        font-weight: 400;
        line-height: normal;
        opacity: .7;
    }
}

.overviewDetailsMethod{
    display: flex;
    flex-direction: column;
}

.overview-title-text{
    font-size: 1.28em;
    font-weight: 600;
    padding-bottom: 1.43rem;
    line-height: 1;
    letter-spacing: .02em;
}

.overview-desc-cont {
    margin-bottom: 3.571rem;
}

.overview-desc-text{
    font-size: 1rem;
    line-height: 2.07;
}

.operation--message{
    font-size: .859rem;
    font-weight: 400;
    line-height: normal;
    opacity: .7;
}

.dd-content-types{
    max-width: none;
}

}

.btn-xs{
min-width: 101px !important;
padding: 0.58rem 0.53rem !important;
font-size: 0.8572rem !important;
border-radius: 4px !important;
&.btn-primary{
    background-color: #0074fb !important;
    color: #fff !important;
    border-color: #0074fb !important;
}

&.btn-light{
    color: #212529;
    background-color: #fff;
    border-color: #fff;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);

    &:hover{
    background-color: rgba(black, 0.1);
    }
}
}

.format-select.form-control.border-0{
border: none !important;
}

.border-danger{
border: 1px solid #ff4259 !important;
* {
    color: #ff4259 !important;
}
}


.commitHistoryInnerWrapper::before {
content: "";
position: absolute;
left: 95px;
top: 0;
border-left: 1px solid rgba(158, 163, 175, 0.2) !important;
height: calc(100%);
}


.itx-comment-wrapper:not(:last-child) {
    padding-bottom: 15px;
}

.itx-comment-wrapper:first-child {
    padding-top: 2rem;
}

.section-space.logCardSpace {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.itx-comment-wrapper:last-child {
    padding-bottom: 2rem;
}

.itx-comment-date {
width: 6.25rem;
padding-top: 2rem;
padding-right: 1rem;

span {
    color: #fff;
    font-size: 10px;
    font-weight: 500;
}

h5 {
    margin-bottom: 0;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}
}


.itx-comment-details-wrapper {
border-radius: 4px;
border: solid 1px rgba(90, 104, 126, 0.2);
background-color: var(--dark-surface-850);
padding: 15px 20px;
margin-left: 20px;
margin-bottom: 20px;
margin-left: auto;
width: calc(100% - 112px);
}

.itx-font-sm {
font-size: 1rem;
}

.itx-comment-details-heading {
font-size: 600;
}

.commentsUsername {
font-size: 0.8rem;
}

.commentsDate {
color: rgba(0, 0, 0, 0.5);
font-style: italic;
font-size: 0.858rem;
padding-left: 10px;
}


.itx-comment-details-wrapper > .itx-comment-details:hover .commentsIcons {
    opacity: 1;
}

.itx-comment-details-wrapper > .itx-comment-details:not(:first-child) {
    border-top: 1px solid #dee2e6;
}

.pt-15{
padding-top: 15px;
}

.pr-20{
padding-right: 20px;
}

.commentsUsername {
    font-size: 0.858rem;
    font-weight: normal;
}

.commentsDate {
color: rgba(0, 0, 0, 0.5);
font-style: italic;
font-size: 0.858rem;
padding-left: 10px;
}

.detailsleftmethod{
padding-right: 0 !important;
padding-left: 0 !important;
}

.detailsLeftTags {
padding: 1.43rem 1.429rem 0.577rem !important;
font-size: .859rem;
font-weight: 400;
line-height: normal;
opacity: .6;
}

.detailsLeftListCont{
padding: 1.11rem 1.43rem;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between;
cursor: pointer;
}

.detailsLeftSummary{
padding-right: 0 !important;
padding-left: 0 !important;
}


.dateActiveIcon {
    right: 1px;
    color: rgba(8, 8, 8, 0.6);
    top: 36px;
    width: 8px;
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 50%;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50%;
        transform: scale(2);
        transform-origin: center;
        opacity: 0.3;
        background-color: #e5e5e5;
    }
}

.itx-comment-wrapper {
    &:hover {
        .dateActiveIcon {
            background-color: #0074fb;

            &:before {
                background-color: #0074fb;
            }
        }
    }
}

.commentsIcons {
    opacity: 0;
}

.itx-comment-details-wrapper > .itx-comment-details:hover .commentsIcons {
    opacity: 1;
}

.markdown p:last-child {
    margin-bottom: 0;
}


.markdown-invalid .CodeMirror,
.markdown-invalid .editor-toolbar {
    border-color: #FF4259 !important;
    opacity: 1;
}

.rounded-icons {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100px;
    color: #112233;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(90, 104, 126, 0.08) !important;
}

.theme--light{
    .changelog-markdown {
        pre {
            code {
            background: #dce5f0;
            }
        }
    }
    .itx-comment-details-wrapper {
        background-color: var(--light-surface-850);
    }
}

.theme--dark{

    .modal-content{
        background-color: #242529;
    }

    .section-space.border-bottom{
        border-color: rgba(255, 255, 255, 0.1) !important;
    }

    .editor-form{ color: white; }

    .modal-title{
        color: white !important;
    }

    .modal-body{
        color: #a3acb5;
    }

    .modal-header, 
    .modal-footer{
        border-color: rgba($color: white, $alpha: 0.15) !important;
    }

    .lds-ring-wrapper{
        background: #242529 !important;
    }

    .diff-check{
        * { border-color: transparent; }
        td {
        background: #1e1f22;
        color: white;

        .d2h-code-line-ctn{
            color: #698f69 !important;
        }

        &.d2h-code-side-linenumber, .d2h-code-line-prefix{
            color: white !important;
        }

        &.d2h-ins{
            background: #3e473e ;
            color: #698f69 ;

            .d2h-code-line-ctn{
            background: black ;
            }
        }
        }

        .d2h-code-side-emptyplaceholder, .d2h-emptyplaceholder{
        background-color: #1e1f22 !important;
        }
    }

    .diff-check{
        * { border-color: transparent; }
        td {
          background: #1e1f22;
          color: #fff;
      
          .d2h-code-line-ctn{
            color: #698f69 !important;
          }
      
          &.d2h-code-side-linenumber, .d2h-code-line-prefix{
            color: #fff !important;
          }
      
          &.d2h-ins{
            background: #3e473e ;
            color: #698f69 ;
      
            .d2h-code-line-ctn{
              background: black ;
            }
          }
          .d2h-code-side-line {
            background-color: #1e1f22 !important;
            color: #fff;
            
          }
        }
      
        .d2h-code-side-emptyplaceholder, .d2h-emptyplaceholder{
          background-color: #1e1f22 !important;
        }
    }
}

.d2h-file-header{
    display: none !important;
}
  