.right-nav-section{
    .active{
        color: var(--primary-color);
        position: relative;
        &::before{
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: var(--primary-color);
            left: -1px;
        }
    }
}

.left-nav{
    &.active{
        background: var(--secondary-200);
        border-radius: 4px;
        border-left: 1px solid var(--secondary-color);
    }
    &.parent.active{
        color: var(--primary-color);
        font-weight: 600;
    }
}

.rhs-tabs{
    >div{
        border-bottom: 2px solid var(--secondary-200);
    }
    >.active{
        font-weight: 600;
        color: var(--primary-color);
        border-bottom-color: var(--primary-color);
    }
}

.guide-toc{
    // position: sticky;
    right: 0;
    bottom: 312px;
}

.guide{
    .guide-body{
        max-width: 1920px;
        min-height: 720px;
    }
    
    .guide-left-panel{
        width: 320px;
    }
    
    .guide-content-panel{
        max-width: 1600px;
    }
    
    .guide-content{
        max-width: 1148px;
    }
    .cards-content{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 24px;
        row-gap: 24px;
        
    }
    .guide-card
    {
        cursor: pointer;
        width: 360px;
        height: 196px;
        border-radius: 8px;
        border: 1px solid var(--secondary-200);
        background: var(--base-color);
        box-shadow: 0px 4px 12px 6px rgba(170, 170, 170, 0.08);
        &:hover{
            background: var(--secondary-200);
            border-color: var(--secondary-color);
        }
    }

    .guide-responsive-container {
        width: 360px;
    }
}


@media screen and (min-width: 1063px) and (max-width: 1463px) {
    .guide{
        .guide-responsive-container{
            width: calc(720px + 24px)
        }
    }
}

@media screen and (min-width: 1463px) {
    .guide{
        .guide-responsive-container{
            width: calc(1080px + 48px)
        }
    }
}


.view-guide{
    .guide-body{
        min-height: 100vh;
    }
}