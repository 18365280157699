.Toastify__toast {
    border-radius: 5px !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
    background-color: var(--dark-surface-base) !important;
    position: relative;
}

.Toastify__close-button, .Toastify__toast-body div:not(.Toastify__toast-icon)
{
  color: var(--dark-text-400) !important ;
}