.manage-guide-left-list-actions{
    .list-actions-cont{
        display: none;
    }
    .documentTitlee{
        cursor: pointer;
        width: 280px;
    }
    &:hover{
        .documentTitlee{
            width: 250px;
        }
        .list-actions-cont{
            display: block;
        }
    }
}

.manage-guide-tabs{
    .tab-content{
        height: calc(100% - 46px);
        &::-webkit-scrollbar {
            display: none;
        }
    }
}