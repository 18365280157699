@media screen and (max-width: 1025px) {
    .customHero1{
        .firstRow{
            padding-right: 0px !important;
        }
    }
}

.handImageSection{
    .imageInsidePhone{
        height: 353px;
        width: 174px;
        position: absolute;
        bottom: 158px;
        left: 120px;
        z-index: 4;
    }
    .handImage{
        position: absolute;
        bottom: 0px;
        z-index: 3;
    }
}

.export_as_svg{
    .svgHere{
        position: absolute;
        bottom: 0;
        z-index: 2;
    }
    .blurredCircle1{
        position: absolute;
        bottom: -10%;
        right: -30%;
        height: 430px;
        width: 430px;
        transform: rotate(-90deg);
        flex-shrink: 0;
        border-radius: 429px;
        background: var(--accent-color);
        filter: blur(86.5px);
    }

    .blurredCircle2{
        position: absolute;
        bottom: -10%;
        left: -30%;
        width: 457px;
        height: 457px;
        transform: rotate(-90deg);
        flex-shrink: 0;
        border-radius: 457px;
        background: var(--secondary-color);
        filter: blur(86.5px);
    }
}