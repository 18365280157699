.banking-dashboard{
    .partnerContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: relative;
        .circlev1{
            width: 474.11px;
            height: 474.11px;
            background: linear-gradient(91.98deg, rgba(255, 138, 199, 0.05) -29.93%, 
                rgba(88, 104, 250, 0.05) 111.93%);
            box-shadow: 0px 4px 28px 6px rgba(73, 70, 89, 0.05);
            border-radius: 100%;
        }
        .circlev2{
            width: 328.94px;
            height: 328.94px;
            background:  linear-gradient(91.98deg, rgba(255, 138, 199, 0.1) -29.93%, 
                rgba(88, 104, 250, 0.1) 111.93%);
            left: 26%;
            top: 16%;
            position: absolute;
            border-radius: 100%;
        }
        .circlev3{
            width: 147.01px;
            height: 147.01px;
            background: rgba(255, 252, 245, 1);
            left: 28%;
            top: 27.5%;
            position: absolute;
            border-radius: 100%;
            z-index: 3;

            img{
                left: 19%;
                top: 46%;
                position: absolute;
            }
        }

        .rotatingIcons{
            background: #FFFCF5;
            box-shadow: 0px 2.80242px 14.0121px -0.700605px rgba(44, 39, 39, 0.1);
            border-radius: 100%;
            position: absolute;
            z-index: 2;
            left: 41.5%;
            top: 38.5%;
            display: flex;
            align-items: center;
            justify-content: center;
            // animation: circle 30s linear infinite;

            &:nth-child(4), &:nth-child(5), &:nth-child(6){
                width: 112.1px;
                height: 112.1px;
            }
            &:nth-child(1), &:nth-child(2), &:nth-child(3){
                width: 90px;
                height: 90px;
            }

            @for $i from 1 through 6 {
                &.rotatingIcons#{$i}{
                    animation-delay: $i * 18s;
                }
            }
        }
    }
}