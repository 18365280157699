.eventSubWrapper{
    padding: 24px 36px;
    border-left: 1px dashed #464646;
    margin-left: 108px;
    position: relative;
    .br-200px{border-radius: 200px;}

    .eachDate{
        position: absolute;
        top: -20px;
        left: -28px;
    }

    .eachEvent{
        width: 442px;
        height: auto;
        background: #292A2F;
        border-radius: 4px;
        position: relative;

        .eventTime{
            position: absolute;
            top: 44%;
            left: -140px;
        }
        .centerSvg{
            position: absolute;
            top: 41%;
            left: -41px;
        }
    }
}

.react-datepicker-wrapper{
    width: 100%;
    input{
        background: var(--dark-surface-875);
        border: 1px solid var(--dark-surface-800) !important;
        height: 40px;
        border-radius: 4px;
        color: var(--light-surface-850);
        outline-width: 0px !important;
        padding-left: 8px !important;
        font-size: 12px;
        width: 100%;
    }
}
.timePicker .react-datepicker-popper{width: 20%;}
.react-datepicker__time-container,
.react-datepicker {
    width: 100% !important;
}

.timePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
    &::-webkit-scrollbar {
        display: none;
    }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    display: none !important;
}
.react-datepicker__header, .react-datepicker__time,
.react-datepicker__month, .react-datepicker__month-container{
    background-color: #292a2f !important;
}
.react-datepicker-time__header,
.react-datepicker__current-month{
    color: var(--dark-text-200) !important;
    font-weight: 400 !important;
}
.react-datepicker__day-name{
    color: var(--dark-text-300) !important;
    font-weight: 400 !important;
}
.react-datepicker__day{
    color: var(--dark-text-400) !important;
    font-weight: 400 !important;

    &:hover{
        background: var(--dark-surface-850) !important;
    }
}
.react-datepicker__day--keyboard-selected{
    background: var(--dark-surface-800) !important;
}
.react-datepicker__day--disabled{
    cursor: not-allowed !important;
    opacity: .7;
}
.react-datepicker{
    border: 1px solid #464646 !important;
}
.react-datepicker__header{
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom: .4px solid #464646 !important;
}
.react-datepicker__time{
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}
.react-datepicker__time-list-item {
    color: white;
    &:hover{
        background: #464646 !important;
    }
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    &::-webkit-scrollbar {
        display: none;
    }
}

.theme--dark{
    .eventMainCard.v2{
        background: var(--base-100) !important;
    }
}

.eventAdd{
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.eventMainCard{
    background: var(--base-100);
    padding: 12px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    height: fit-content;

    flex-direction: column;

    &.v2{
        background: var(--base-color) !important;
    }

    img {
        height: 210px;
        width: 370px;
        object-fit: cover;
        border-radius: 6px;
    }

    .eventTimings{
        color: var(--content-subtle);
        font-size: .9rem;
        font-weight: 400;
    }

    .eventTitle{
        font-weight: 600;
        color: var(--content-color);
        font-size: 1rem;
    }

    .eventDescription{
        margin-top: 12px !important;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        font-weight: 400;
        color: var(--content-subtle);
        font-style: .85rem;
        width: 368px;
        max-height: 72px;
    }

    .eventLocation{
        color: var(--content-color);
        font-style: .85rem;
        font-weight: 500;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        width: 368px;
        max-height: 72px;
        overflow: hidden;
    }

    .eventMainButton{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border: 1px solid var(--primary-color);
        border-radius: 4px;
        padding: 8px 32px;

        p{
            color: var(--primary-color);
            font-style: .85rem;
            font-weight: 600;
        }

        &:hover{
            border: 1px solid transparent;
            background: var(--primary-color);

            p{
                color: white;
            }
            svg, polyline, line{
                stroke: white !important;
            }
        }
    }
}
.upcomingEVentsText{
    color: var(--content-color);
    font-size: 2.1rem;
    font-weight: 600;
}

.react-datepicker__day--selected{
    background-color: #216ba5 !important;
    color: white !important;
}