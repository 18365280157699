.pos-calendardd{
    top: 36px;
    left: 0px;
}

.pos-picker{
    top: 40px;
    left: 0;
}

.rdrDayNumber span{
    color: white !important;
}

.rdrCalendarWrapper{
    color: white !important;
}

.rdrWeekDay{
    color: white !important;
}

.rdrNextPrevButton{
    background-color: grey !important;
}
.rdrNextPrevButton i{
    color: antiquewhite !important;
}

.rdrMonthAndYearPickers select{
    color: white !important;
}

// .rdrMonthAndYearPickers select{
//     color: var(--light-text-300) !important;
//     background-image: url("data:image/svg+xml;utf8,<svg width=%279px%27 height=%276px%27 viewBox=%270 0 9 6%27 version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27><g id=%27Artboard%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 transform=%27translate%28-636.000000, -171.000000%29%27 fill-opacity=%270.368716033%27><g id=%27input%27 transform=%27translate%28172.000000, 37.000000%29%27 fill=%27%230E242F%27 fill-rule=%27nonzero%27><g id=%27Group-9%27 transform=%27translate%28323.000000, 127.000000%29%27><path d=%27M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z%27 id=%27arrow%27></path></g></g></g></svg>");
// }

.rdrMonthPicker select{
    color: white !important;
    background-image: url("data:image/svg+xml;utf8,<svg width=%279px%27 height=%276px%27 viewBox=%270 0 9 6%27 version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27><g id=%27Artboard%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27 transform=%27translate%28-636.000000, -171.000000%29%27 fill-opacity=%270.368716033%27><g id=%27input%27 transform=%27translate%28172.000000, 37.000000%29%27 fill=%27%230E242F%27 fill-rule=%27nonzero%27><g id=%27Group-9%27 transform=%27translate%28323.000000, 127.000000%29%27><path d=%27M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z%27 id=%27arrow%27></path></g></g></g></svg>");
}

.pos-searchicon{
    left: 0;
    top: 0;
}

input:focus{
    // border: 1px solid var();
    outline: none;
}

.input-box{
    right: 0;
    top: 0;
    border: none;
    color: var(--dark-text-200);
}

.circularAvatar{
    
}

.logSubWrapper{
    padding: 15px 0 0 32px;
    border-left: 1px dashed #464646;
    margin-left: 108px;
    position: relative;
    .br-200px{border-radius: 200px;}

    .eachDate{
        position: absolute;
        top: -20px;
        left: -28px;
    }

    .eachEvent{
        background: #292A2F;
        border-radius: 4px;
        position: relative;

        .eventTime{
            position: absolute;
            top: 44%;
            left: -130px;
        }
        .centerSvg{
            position: absolute;
            top: 41%;
            left: -36px;
        }

        .log-card{
            padding: 23px 19px;
        }
    }
}

.fancy-select{
    padding: 1px 16px;
    width: max-content;
    border-radius: 38px;
    font-weight: 500;
    font-size: 11px;
    height: max-content;
    // line-height: 100%;

    &.GET{
        background: var(--dark-colors-grass-06);
        border: 1px solid var(--dark-colors-grass-11);
    }

    &.POST{
        background: var(--dark-colors-amber-05);
        border: 1px solid var(--dark-colors-amber-11);
    }

    &.PUT{
        background: var(--dark-colors-blue-05);
        border: 1px solid var(--dark-colors-blue-11);
    }

    &.DELETE{
        background: var(--dark-colors-red-05);
        border: 1px solid var(--dark-colors-red-11);
    }

    &.OPTIONS{
        background: var(--dark-colors-violet-05);
        border: 1px solid var(--dark-colors-violet-11);
    }

    &.PATCH{
        background: var(--dark-colors-purple-05);
        border: 1px solid var(--dark-colors-purple-11);
    }
}