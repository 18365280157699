.loginPageSection{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 80px 32px 48px;

    .loginContainer{width: 360px;}
    .titleText{
        font-weight: 600;
        font-size: 2rem;
        line-height: 38px;
        text-align: center;
        color: var(--content-color);

        &.v2{
            font-size: 1.5rem;
        }
    }
    .descText{
        margin-top: 12px !important;
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        color: var(--content-subtle);

        &.v2{
            font-size: .95rem;
        }
    }
    .inputText{
        color: var(--content-color);
        font-size: .95rem;
    }

    .v1-form-control{
        background: var(--base-color) !important;
        border: 1px solid var(--base-200) !important;
        box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.08), inset 1px 1px 2px rgba(0, 0, 0, 0.08) !important;
        border-radius: 4px !important;

        font-size: .8rem !important;
        color: var(--content-subtle) !important;

        &::placeholder{
            opacity: .7;
            font-size: .8rem !important;
            color: var(--content-subtle) !important;
        }

        &.error{
            border-color: red !important;
        }
    }

    .eyeIconPass{
        position: absolute;
        bottom: 9.5px;
        right: 12px;
    }

    .forgotPassword *{
        color: var(--primary-color);
        font-size: .9rem;
    }

    .signInWithAPIwiz{
        width: 100%;
        padding: 10px 16px;
        background: var(--base-color);
        cursor: pointer;
        border: 1px solid var(--base-200);
        box-shadow: 0px 1px 6px rgba(16, 24, 40, 0.04);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        .pTag{
            margin-left: 12px !important;
            font-weight: 700;
            color: var(--content-color);
            font-size: .9rem;
        }
    }

    .loginWithSSO{
        color: var(--primary-color);
        font-weight: 700;
        font-size: .85rem;
        text-align: center;
        display: block;
    }

    .dontHaveAnAccount{
        * {color: var(--content-subtle);}
        font-weight: 500;
        font-size: .85rem;
        text-align: center;

        span{
            color: var(--primary-color);
            font-weight: 700;
        }
    }

    .borderChange{
        border: 1px solid #EAECF0;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
}

// captcha
.loginPageSection{

    .register-captcha-reload {
        height: 2rem;
        width: 2rem;
    }

    .captcha-text-container {
        position: relative;
    }

    .captcha-text-container > div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }
    #captcha-reload-text {
        cursor: pointer;
    }

    #captcha-text-canvas {
        height: 60px;
        background-color: #F4F5F5;
    }

    .check-captcha-btn {
        background-color: #0074fa !important;
        color: #fff !important;
        border-radius: 0.5rem;
        outline: none;
        border: none;
    }

    .check-captcha-btn.matched {
        background-color: #63C174 !important;
    }

    .captcha-text-input {
        flex-grow: 1;
        border-radius: 4px;
        outline: 1px solid transparent;
        border: 1px solid #b8b8b8;
        padding: 0 0.8rem;
        transition: 0.15s ease-in;

        &:focus {
            border-color: #0074fb;
            border-width: 2px;
        }
    }

    .captcha-text-input.error {
        border: 2px solid #ff4259 !important;
        box-shadow: none;
    }

    .captcha-text-input:read-only {
        border: none !important;
        background-color: #e9ecef;
    }

    .captcha-text-input:read-only:focus, .captcha-text-input:read-only:active{
        cursor: not-allowed;
        transition: none !important;
    }

    .check-captcha-btn:disabled {

        cursor: not-allowed;
    }

    .captcha-input-container {
        display: flex;
        gap: 1rem;
        height: 2.5rem;
        width: 100%;
    }

}

.contactUsSection{
    .contactUs{
        color: var(--primary-color);
        font-size: .9rem;
        font-weight: 600;
    }

    .getInTouch{
        margin-top: 12px !important;
        font-size: 2rem;
        font-weight: 600;
        color: var(--content-color);
        margin-bottom: 18px !important;
    }

    .contactDesc{
        font-weight: 400;
        color: var(--content-subtle);
        font-size: 1.1rem;
    }

    .formLabell{
        color: var(--content-color) !important;
    }

    .purple-form-control{
        background: var(--base-color) !important;
        border: 1px solid var(--base-200) !important;
        box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.08), inset 1px 1px 2px rgba(0, 0, 0, 0.08) !important;
        border-radius: 4px !important;

        font-size: .8rem !important;
        color: var(--content-subtle) !important;

        &::placeholder{
            opacity: .7;
            font-size: .8rem !important;
            color: var(--content-subtle) !important;
        }
    }
}

.gradientBtnInAuth{
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    width: 100%;
    padding: 10px 18px;
    text-align: center;
    font-size: .9rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(255, 251, 251, 0.2) 0.53%, rgba(255, 255, 255, 0) 100%), var(--primary-color);
}

.v1-form-control-public{
    background: var(--base-color) !important;
    border: 1px solid var(--base-200) !important;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.08), inset 1px 1px 2px rgba(0, 0, 0, 0.08) !important;
    border-radius: 4px !important;

    font-size: .8rem !important;
    color: var(--content-subtle) !important;

    &::placeholder{
        opacity: .7;
        font-size: .8rem !important;
        color: var(--content-subtle) !important;
    }

    &.error{
        border-color: red !important;
    }
}