.tabs-container{
    background-color: var(--dark-surface-875);
    /*
    .tab-content{
        background: var(--dark-surface-900);
    }
    */
}
.tabs-btn-2{
    width: 200px;
    height: 46px;
    transition: none !important;
    font-size: 14px !important;
    color: var(--dark-text-400) !important;
    font-weight: 500;
}

.tabs-btn-2.active{
    border-bottom: 2px solid var(--dark-colors-blue-09) !important;
    color: var(--dark-text-50)  !important;;
}

.nav-tabs{
    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: var(--dark-surface-875);;
    --bs-nav-tabs-link-active-color: #fdfdfd;
    --bs-nav-tabs-link-active-bg: var(--dark-surface-900);
    --bs-nav-tabs-link-active-border-color: var(--dark-surface-875) var(--dark-surface-875) var(--dark-surface-900);
}

.nav-tabs .nav-link{
    border-radius: 0;
    color: #7a7a7a;
    font-size: 12px;
    background: var(--dark-surface-875);
    border: none;
}

.tab-content{
    height: 100%;
    overflow: auto;
    background: var(--dark-surface-900);
}

.theme--light{
    .consumer-tabs{
        .tabs-btn-2{
            color: var(--light-text-consumer-400) !important;
        }
        
        .tabs-btn-2.active{
            border-bottom: 2px solid var(--primary-color) !important;
            color: var(--light-text-consumer-50)  !important;;
        }
        
        .nav-tabs{
            --bs-nav-tabs-border-width: 1px;
            --bs-nav-tabs-border-color: var(--light-consumer-surface-875);;
            --bs-nav-tabs-link-active-color: #fdfdfd;
            --bs-nav-tabs-link-active-bg: var(--light-consumer-surface-900);
            --bs-nav-tabs-link-active-border-color: var(--light-surface-consumer-875) var(--light-surface-consumer-875) var(--light-surface-consumer-900);
        }
        
        .nav-tabs .nav-link{
            border-radius: 0;
            color: #7a7a7a;
            font-size: 12px;
            background: var(--light-surface-consumer-875);
            border: none;
        }
        
        .tab-content{
            height: 100%;
            overflow: auto;
            background: var(--light-surface-consumer-900);
        }
    }
}

.consumer-tabs{
    .tabs-btn-2{
        color: var(--dark-text-consumer-400) !important;
    }
    
    .tabs-btn-2.active{
        border-bottom: 2px solid var(--primary-color) !important;
        color: var(--dark-text-consumer-50)  !important;;
    }
    
    .nav-tabs{
        --bs-nav-tabs-border-width: 1px;
        --bs-nav-tabs-border-color: var(--dark-consumer-surface-875);;
        --bs-nav-tabs-link-active-color: #fdfdfd;
        --bs-nav-tabs-link-active-bg: var(--dark-consumer-surface-900);
        --bs-nav-tabs-link-active-border-color: var(--dark-surface-consumer-875) var(--dark-surface-consumer-875) var(--dark-surface-consumer-900);
    }
    
    .nav-tabs .nav-link{
        border-radius: 0;
        color: #7a7a7a;
        font-size: 12px;
        background: var(--dark-surface-consumer-875);
        border: none;
    }
    
    .tab-content{
        height: 100%;
        overflow: auto;
        background: var(--dark-surface-consumer-900);
    }
}