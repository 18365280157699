.sdkCard{
    width: 473px;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 16px;

    img {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }
}

.useCaseCard{
    width: 260px;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 16px;

    img {
        height: 161px;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
    }
}

.searchSection{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 14px;

    background: var(--base-color);
    width: fit-content;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    input{
        margin-left: 10px;
        width: 224px;
        border: none;
        outline-width: 0px;
        color: #667085;
        font-size: 1rem;
        background: none;

        &::placeholder{
            color: #667085;
        }
    }
}

.sdksLeftPanel{

    .subTitle{
        font-weight: 600;
        color: var(--primary-color);
        font-size: .8rem;
    }

    .categoryListSDK{
        .eachCategory{
            cursor: pointer;
            padding: 8px 14px;
            font-size: .9rem;
            font-weight: 600;
            color: var(--content-color);
            margin-bottom: 10px;
            text-transform: capitalize;

            &.active{
                border-left: 1px solid var(--primary-color);
                color: var(--primary-color) !important;
            }
        }
    }
}

.sdkCardMainWrapper{
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 28px;
    .sdkCardMain{
        width: 473px;
        height: auto;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        background: var(--base-color);
        border: 1px solid var(--secondary-200);
        box-shadow: 0px 4px 12px 6px rgba(170, 170, 170, 0.1);
        border-radius: 8px;

        &.isUsecase{
            width: 300px !important;

            .imageSection, .detailsSection{
                width: 100%;
            }
        }

        .imageSection{
            width: 49%;
            img{
                height: 215px;
                width: 100%;
                object-fit: cover;
            }
        }

        .detailsSection{
            width: 49%;

            .categoryBadge{
                width: fit-content;
                background: var(--secondary-200);
                padding: 4px 8px;
                border-radius: 12px;

                color: var(--primary-color);
                font-size: .8rem;
                font-weight: 500;
                text-transform: capitalize;

                margin-bottom: 20px;
            }

            .eachSDKTitle{
                margin-top: 20px;
                color: var(--content-color);
                font-size: 1rem;
                margin-bottom: 8px !important;
                font-weight: 700;
            }

            .eachSDKDescription{
                height: 60px;
                p{
                    font-size: .9rem;
                    color: var(--content-subtle);
                    font-weight: 400;
                }
            }

            .btnTextt{
                font-size: .9rem;
            }
        }
    }
}

.dashboardCard{
    background: #292A2F;
    height: 470px;
    border: 1px solid #2F3135;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 4px;
}

.paginationPrevious{
    border:1px solid var(--gray-300, #D0D5DD);
}

.paginationV2{
        .active{
            background-color:var(--light-violet-purple-theme-secondary-200, #F9F2FD);
            border-radius: 8px;
            p{
                color: black;
            }
    }
}

.specification-modal-download-btn {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}

.showDownloadChild{
    opacity: 0;
}

.showDownload:hover{
    .showDownloadChild{
       opacity: 1;
    }
}

.swagger-code-generator-modal-body {
    font-size: 0.8rem !important;
}

.swagger-code-generator-step-icon {
    height: 1.5rem;
    width: 1.5rem;
    background-color: var(--primary-color);
    color: #fff;
    margin-right: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swagger-code-generator-step-heading {
    font-size: 1rem;
}
  
.swagger-code-generator-step-heading.inactive {
    opacity: 0.5;
}