.theme--light{
    .v1--styles{
        .v1-border-storkes-800-01 {
            border: 1px solid var(--light-strokes-800);
        }

        .text-gray-subtext {
            color: var(--light-gray-subtext);
        }
        
        .text-gray-subtext-subtle {
            color: var(--light-gray-subtext-subtle);
        }
    }
}

.v1--styles {
    // * {
    //     font-family: 'Jost', sans-serif;
    // }

    .fixedLeftBar{
        height: calc(100vh); 
        position: sticky;
        z-index: 9;
        top: 60px;
        overflow-x: hidden;
        left: 0;
    }
    .fixedRightBar{
        height: calc(100vh); 
        position: sticky;
        z-index: 9;
        top: 60px;
        overflow-x: hidden;
        left: 0;
    }
    .footerSectionMain{
        z-index: 10;
    }

    .fadeIn {
        animation: fadeIn 1.2s ease-in-out;
    }

    .purple-form-control {
        border: 1px solid #EBE4F2;
    }

    .bg-transparent {
        background: transparent;
    }

    .border-none {
        border: none;
    }

    .outline-none {
        outline: none;
    }

    .resize-none{
        resize: none;
    }

    .input-transparent{
        border: none;
        outline: none;
        background: transparent;
    }

    @for $i from 1 through 12 {
        .text-truncate-#{$i}{
            -webkit-line-clamp: #{$i};
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;    
        }
    }

    // Border Solid 
    @for $i from 0 through 100 {
        .border-#{$i}px{
            border: #{$i}px solid !important;
        }
    }

    // margins 
    @for $i from 0 through 100 {
        .m-#{$i}px {
            margin: #{$i}px !important;
        }

        .mt-#{$i}px {
            margin-top: #{$i}px !important;
        }

        .mt--#{$i}px {
            margin-top: -#{$i}px !important;
        }

        .mb-#{$i}px {
            margin-bottom: #{$i}px !important;
        }

        .ml-#{$i}px {
            margin-left: #{$i}px !important;
        }

        .mr-#{$i}px {
            margin-right: #{$i}px !important;
        }

        .mx-#{$i}px {
            margin-left: #{$i}px !important;
            margin-right: #{$i}px !important;
        }

        .my-#{$i}px {
            margin-top: #{$i}px !important;
            margin-bottom: #{$i}px !important;
        }
    }


    @for $i from 0 through 1000 {
        .zIndex-#{$i} {
            z-index: #{$i};
        }
    }

    .px-81px {
        padding-left: 81px;
        padding-right: 81px;
    }

    // paddings 
    @for $i from 0 through 100 {
        .p-#{$i}px {
            padding: #{$i}px !important;
        }

        .pt-#{$i}px {
            padding-top: #{$i}px !important;
        }

        .pb-#{$i}px {
            padding-bottom: #{$i}px !important;
        }

        .pl-#{$i}px {
            padding-left: #{$i}px !important;
        }

        .pl-#{$i}px-important {
            padding-left: #{$i}px !important;
        }

        .pr-#{$i}px {
            padding-right: #{$i}px !important;
        }

        .pr-#{$i}px-important {
            padding-right: #{$i}px !important;
        }

        .px-#{$i}px {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        .px-#{$i}px-important {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        .py-#{$i}px {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }

        .py-#{$i}px-important {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }
    }

    .py-92px {
        padding-top: 92px;
        padding-bottom: 92px;
    }

    // border radius
    @for $i from 0 through 40 {
        .br-#{$i}px {
            border-radius: #{$i}px;
        }

        .br-t-#{$i}px {
            border-top-right-radius: #{$i}px;
            border-top-left-radius: #{$i}px;
        }

        .br-b-#{$i}px {
            border-bottom-right-radius: #{$i}px;
            border-bottom-left-radius: #{$i}px;
        }

        .br-tr-#{$i}px {
            border-top-right-radius: #{$i}px !important;
        }

        .br-tl-#{$i}px {
            border-top-left-radius: #{$i}px !important;
        }

        .br-br-#{$i}px {
            border-bottom-right-radius: #{$i}px;
        }

        .br-bl-#{$i}px {
            border-bottom-left-radius: #{$i}px !important;
        }
    }

    .br-200 {
        border-radius: 200px;
    }

    .br-50 {
        border-radius: 50%;
    }

    // font sizes
    @for $i from 7 through 100 {
        .fs-#{$i}px {
            font-size: #{$i}px;
        }

        .lh-#{$i}px {
            line-height: #{$i}px;
        }
    }

    .fs-italic {
        font-style: italic;
    }

    // font weights
    @for $i from 1 through 7 {
        .fw-#{$i * 100} {
            font-weight: #{$i * 100};
        }
    }

    // font family
    .ff-inter {
        font-family: 'Inter';
        font-style: normal;
    }

    // gaps
    @for $i from 1 through 100 {
        .gap-#{$i}px {
            gap: #{$i}px;
        }

        .gap-#{$i}px-important {
            gap: #{$i}px !important;
        }

        .column-gap-#{$i}px {
            column-gap: #{$i}px;
        }

        .row-gap-#{$i}px {
            row-gap: #{$i}px;
        }

    }

    //absolute position
    @for $i from 0 through 40 {
        .ab-r-#{$i}px {
            right: #{$i}px;
        }

        .ab-r-#{$i}px-important {
            right: #{$i}px !important;
        }

        .ab-t-#{$i}px {
            top: #{$i}px;
        }

        .ab-t--#{$i}px {
            top: -#{$i}px;
        }

        .ab-b-#{$i}px {
            bottom: #{$i}px;
        }

        .ab-l-#{$i}px {
            left: #{$i}px;
        }
    }

    // opacity
    @for $i from 0 through 10 {
        .o-0#{$i} {
            opacity: $i * 0.1
        }
    }

    // widths
    .w-fit {
        width: fit-content;
    }

    .w-56px {
        width: 56px;
    }

    .w-128px {
        width: 128px
    }

    .w-117px {
        width: 117px
    }

    .w-218px {
        width: 218px
    }

    .w-360px {
        width: 360px
    }

    .w-max {
        width: max-content
    }

    // heights
    .h-fit {
        height: fit-content !important;
    }

    .min-h-100vh{
        min-height: 100vh;
    }

    @for $i from 0 through 600 {
        .h-#{$i} {
            height: calc($i * 1%);
        }

        .h-#{$i}vh {
            height: #{$i}vh;
        }

        .h-#{$i}px {
            height: #{$i}px;
        }

        .h--#{$i}px {
            height: calc(100% - #{$i}px);
        }

        .min-h-#{$i}px {
            min-height: #{$i}px;
        }

        .min-h-#{$i}vh {
            min-height: #{$i}vh;
        }


        .w-#{$i} {
            width: calc($i * 1%);
        }

        .w-#{$i}vw {
            width: #{$i}vw;
        }

        .w-#{$i}px {
            width: #{$i}px;
        }

        .w--#{$i}px {
            width: calc(100% - #{$i}px);
        }

        .max-w-#{$i} {
            max-width: calc($i * 1%);
        }

        .max-w-#{$i}-important {
            max-width: calc($i * 1%) !important;
        }

        .max-w-#{$i}px {
            max-width: #{$i}px;
        }

        .min-w-#{$i} {
            min-width: calc($i * 1%);
        }

        .min-w-#{$i}px {
            min-width: #{$i}px;
        }
    }

    @for $i from 101 through 200 {

        .h-#{$i}px {
            height: #{$i}px;
        }

        .h--#{$i}px {
            height: calc(100% - #{$i}px);
        }

        .w-#{$i}px {
            width: #{$i}px;
        }

        .w--#{$i}px {
            width: calc(100% - #{$i}px);
        }
    }

    .h-46px {
        height: 46px !important;
    }

    .container {
        padding: 0px !important;
    }

    @media screen and (min-width: 1300px) {
        .container {
            max-width: 1024px;
        }
    }

    // colors
    .surface-950 {
        background: var(--dark-surface-950);
    }

    .surface-900 {
        background: var(--dark-surface-900);
    }

    .surface-900-important {
        background: var(--dark-surface-900) !important;
    }

    .surface-875 {
        background: var(--dark-surface-875);
    }

    .surface-850 {
        background: var(--dark-surface-850);
    }

    .surface-800 {
        background: var(--dark-surface-800);
    }

    .color-800 {
        color: var(--dark-text-400);
    }

    .surface-700 {
        background: var(--dark-surface-700);
    }

    .surface-600 {
        background: var(--dark-surface-600);
    }

    .surface-base {
        background: var(--dark-surface-base);
    }

    .surface-pure {
        background: var(--dark-surface-pure);
    }

    .bg-theme-base {
        background: var(--base-color);
    }

    .bg-theme-base-100{
        background: var(--base-100);
    }

    .bg-theme-base-200 {
        background: var(--base-200);
    }

    .bg-theme-secondary-200 {
        background: var(--secondary-200);
    }

    .bg-theme-primary-subtle {
        background: var(--primary-subtle);
    }

    .bg-theme-secondary {
        background: var(--secondary-color);
    }

    .bg-theme-primary {
        background: var(--primary-color);
    }

    .bg-theme-surface-875 {
        background: var(--light-surface-875);
    }

    .bg-theme-surface-900 {
        background: var(--light-surface-900);
    }

    .bg-theme-surface-base {
        background: var(--light-surface-base);
    }

    .bg-primary {
        background: var(--dark-colors-blue-09) !important;
    }

    .bg-danger {
        background: var(--dark-colors-red-09) !important;
    }

    .bg-success {
        background: var(--dark-colors-grass-09) !important;
    }

    .bg-red-05 {
        background: #541B1F;
    }

    .text-50 {
        color: var(--dark-text-50) !important
    }

    .text-100 {
        color: var(--dark-text-100)
    }

    .text-100-important {
        color: var(--dark-text-100) !important
    }

    .text-200 {
        color: var(--dark-text-200);
    }

    .text-300 {
        color: var(--dark-text-300)
    }

    .text-400 {
        color: var(--dark-text-400)
    }

    .text-gray-500{
        color: var(--gray-500);
    }

    .red-11 {
        color: var(--dark-colors-red-11);
    }

    .red-09 {
        color: var(--dark-colors-red-09);
    }

    .grass-09 {
        color: var(--dark-colors-grass-09);
    }

    .text-danger {
        color: var(--dark-colors-red-09) !important;
    }

    .text-success {
        color: var(--dark-colors-grass-09) !important;
    }

    .text-warning {
        color: var(--dark-colors-amber-09) !important;
    }

    .text-primary {
        color: var(--dark-colors-blue-11) !important;
    }

    .text-theme-primary {
        color: var(--primary-color) !important;
    }

    .text-theme-secondary {
        color: var(--secondary-color) !important;
    }

    .text-theme-base {
        color: var(--base-color) !important;
    }

    .text-theme-secondary-100 {
        color: var(--secondary-100) !important;
    }

    .text-theme-content-color {
        color: var(--content-color) !important;
    }

    .text-theme-content-subtle {
        color: var(--content-subtle) !important;
    }

    .text-theme-accent {
        color: var(--accent-color);
    }
    
    .text-gray-subtext {
        color: var(--dark-gray-subtext);
    }
    
    .text-gray-subtext-subtle {
        color: var(--dark-gray-subtext-subtle);
    }

    .text-center {
        text-align: center;
    }

    .text-center-important {
        text-align: center !important;
    }

    .fill-base {
        fill: var(--dark-surface-base);
    }

    .v1-border-strokes-1{
        border: 1px solid var(--dark-strokes-800);
    }

    .v1-border-1 {
        border: 1px solid var(--dark-surface-800);

        // border-radius: 4px;
        &.important {
            border-color: var(--dark-surface-800) !important;
        }
    }

    .v1-border-1-important {
        border: 1px solid var(--dark-surface-800) !important;
    }

    .v1-border-1pt5 {
        border: 1.5px solid var(--dark-surface-800);
    }

    .v1-border-error {
        border-color: var(--delete-dark) !important;
    }

    .v1-border-storkes-800-01 {
        border: 1px solid var(--dark-strokes-800);
    }

    .v1-border-01 {
        border: 1px solid var(--dark-surface-850);
    }

    .v1-border-03 {
        border: 1px solid #464646;
    }

    .v1-border-05 {
        border: 0.5px solid var(--dark-surface-800);
    }

    .v1-border-06 {
        border: 0.5px solid var(--dark-surface-700);
    }

    .v1-border-05 {
        border: 0.5px solid var(--dark-surface-800);
    }

    .v1-border-06 {
        border: 0.5px solid var(--dark-surface-700);
    }

    .v1-border-left-1 {
        border-left: 0.5px solid var(--dark-surface-800) !important;
    }

    .v1-border-left-2 {
        border-left: 1px solid var(--dark-surface-800) !important;
    }

    .v1-border-bottom-1 {
        border-bottom: 0.5px solid var(--dark-surface-800) !important;
    }

    .v1-border-bottom-05 {
        border-bottom: 1px solid var(--dark-surface-850);
    }

    .v1-border-red {
        border: 1px solid rgba(255, 99, 105, 1);
    }

    .v1-border-bottom-2 {
        border-bottom: 1px solid #464646;
    }

    .v1-border-right-1 {
        border-right: 0.5px solid var(--dark-surface-800);
    }

    .v1-border-left-1 {
        border-left: 1px solid var(--dark-surface-800) !important;
    }

    .v1-border-blue {
        border: 1px solid #0091FF !important;
    }

    .border-theme-primary{
        border: 1px solid var(--primary-color);
        &._2px{
            border-width: 2px;
        }
    }

    .border-theme-base-100{
        border: 1px solid var(--base-100);
    }

    .border-theme-base-200 {
        border: 1px solid var(--base-200);
    }

    .border-theme-primary-subtle {
        border: 1px solid var(--primary-subtle);
    }

    .border-theme-secondary-200 {
        border: 1px solid var(--secondary-200);
    }

    .border-theme-secondary {
        border: 1px solid var(--secondary-color);
    }

    .border-theme-content-subtle {
        border: 1px solid var(--content-subtle);
        &._2px{
            border-width: 2px;
        }
        &._3px{
            border-width: 3px;
        }
    }

    .border-strokes-800 {
        border-color: var(--dark-strokes-consumer-800) !important;
    }

    .border-strokes-600 {
        border-color: var(--dark-strokes-consumer-600) !important;
    }

    .border-top-theme-primary {
        border-top: 1px solid var(--primary-color);
        &._1-5px{
            border-top-width: 1.5px;  
        }
    }

    .border-top-theme-secondary-200 {
        border-top: 1px solid var(--secondary-200);
        &._2px{
            border-top-width: 2px;
        }
    }
    

    .border-right-theme-base-200 {
        border-right: 1px solid var(--base-200);
    }



    .border-left-theme-base-200 {
        border-left: 1px solid var(--base-200);
    }

    .border-left-theme-primary {
        border-left: 1px solid var(--primary-color);
    }

    .border-left-theme-secondary {
        border-left: 1px solid var(--secondary-color);
    }

    .border-left-theme-secondary-200 {
        border-left: 1px solid var(--secondary-200);
    }

    

    .border-bottom-800{
        border-bottom: 1px solid var(--dark-surface-800);
    }

    .border-bottom-theme-primary{
        border-bottom: 1px solid var(--primary-color);
        &._2px{
            border-top-width: 2px;
        }
    }

    .border-bottom-theme-primary-subtle{
        border-bottom: 1px solid var(--primary-subtle);
    }

    .border-bottom-theme-secondary-200 {
        border-bottom: 1px solid var(--secondary-200);
    }

    .border-bottom-theme-base-100 {
        border-bottom: 1px solid var(--base-100);
    }

    .cursor {
        cursor: pointer;
    }

    .cursor-not-allowed {
        cursor: not-allowed;
    }

    .hover-bg:hover {
        cursor: pointer;
        background: var(--dark-surface-875);
    }

    .hover-surface-850:hover {
        background: var(--dark-surface-850);
        cursor: pointer;
    }

    .hover-surface-base:hover {
        background: var(--dark-surface-base);
    }

    .hover-surface-875:hover {
        background: var(--dark-surface-875);
        cursor: pointer;
    }

    .hover-surface-900:hover {
        background: var(--dark-surface-900);
        cursor: pointer;
    }

    .hover-underline:hover {
        text-decoration: underline;
    }

    .text-underscore {
        text-decoration: underline;
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    .no-box-shadow {
        box-shadow: none;
    }

    .swagger-tag {
        padding: 4px 8px;
        background: var(--dark-surface-850);
        border: 0.5px solid var(--dark-surface-800);
        border-radius: 38px;
    }

    .api-method-tag {
        &.post {
            background-color: var(--light-colors-grass-06);
        }

        &.get {
            background-color: var(--light-colors-blue-06);
        }

        &.delete {
            background-color: var(--light-colors-red-05);
        }

        &.put {
            background-color: var(--light-colors-amber-05);
        }

        &.options {
            background-color: var(--light-colors-violet-05);
        }

        &.patch {
            background-color: var(--light-colors-purple-05);
        }

        &.trace {
            background-color: var(--light-colors-orange-05);
        }

        &.head {
            background-color: var(--light-colors-orange-05);
        }
    }

    .status-color-group {
        ._1XX {
            color: var(--light-colors-blue-11);
        }

        ._2XX {
            color: var(--light-colors-grass-11);
        }

        ._3XX {
            color: var(--light-colors-amber-11);
        }

        ._4XX,
        ._5XX {
            color: var(--light-colors-red-11);
        }
    }

    .status-group-chip {
        &._1XX {
            background: var(--light-colors-blue-06);
        }

        &._2XX {
            background: var(--light-colors-grass-06);
        }

        &._3XX {
            background: var(--light-colors-amber-06);
        }

        &._4XX,
        &._5XX {
            background: var(--light-colors-red-06);
        }
    }



    .v1-form-control {
        background: var(--dark-surface-875);
        border: 1px solid var(--dark-surface-800) !important;
        height: 40px;
        border-radius: 4px;
        color: var(--light-surface-850);
        outline-width: 0px !important;
        padding-left: 8px !important;
        font-size: 12px;

        &.v1-form-control-public{
            border: 1px solid var(--base-200) !important;
        }

        &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }

        &::placeholder {
            opacity: 0.8;
            color: var(--dark-text-400);
        }
    }

    .connectorCard {
        width: 320px;

        .activeTag {
            background: #2F3135;
            border-radius: 38px;
            padding: 4px 6px 4px 4px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 4px;

            .greenDot {
                width: 10px;
                height: 10px;
                border-radius: 200px;
                background: #4FA657;
            }

            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                color: #63C174;
            }
        }
    }

    .v1-form-textarea {
        background: var(--dark-surface-875);
        border: 1px solid var(--dark-surface-800);
        border-radius: 4px;
        outline-width: 0px !important;
        padding: 8px !important;
        color: var(--light-surface-850);
        font-size: 12px;

        &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }

        &::placeholder {
            opacity: 0.8;
            color: var(--dark-text-400);
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .btn-sm-v1 {
        background: var(--dark-surface-850);
        border: 0.5px solid #464646;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        cursor: pointer;
    }

    .v1-btn-primary {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid #52A9FF !important;
        background: var(--dark-colors-blue-09) !important;
        font-size: 14px;
    }

    .v1-btn-secondary {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid var(--dark-strokes-800) !important;
        background: var(--dark-surface-850) !important;
    }

    .btn-md-v1 {
        cursor: pointer;
        background: var(--dark-surface-875);
        border: 1px solid var(--dark-strokes-800);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 16px;
    }

    .btn-md-850 {
        cursor: pointer;
        background: var(--dark-surface-850);
        border: 1px solid var(--dark-surface-800);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
    }

    .text-btn-md {
        cursor: pointer;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
    }

    .v1select {
        span[class$='indicatorSeparator'] {
            display: none;
        }

        div[class$='control'] {
            background: var(--dark-surface-875) !important;
            outline-width: 0 !important;
            border: 1px solid var(--dark-surface-800) !important;
            height: 40px !important;

            * {
                color: white;
            }
        }

        &.surface-850 {
            div[class$='control'] {
                background: var(--dark-surface-850) !important;
            }
        }

        &.border-right-0 {
            div[class$='control'] {
                background: var(--dark-surface-850) !important;
                border-right: 0px solid transparent !important;
                border-top-right-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
            }
        }

        &.h-auto {
            div[class$='control'] {
                height: fit-content !important;
            }
        }

        &.h-32 {
            div[class$='control'] {
                height: 32px !important;
                min-height: 32px !important;

                div[class$='ValueContainer'],
                >div:nth-child(1) {
                    min-height: 32px;
                    padding: 1.5px 8px 4px 4px;
                }

                div[class$='IndicatorsContainer'],
                >div:nth-child(2) {
                    min-height: 30px;
                    height: 30px;

                    div[class$='indicatorContainer'] {
                        padding: 4px;
                    }
                }
            }
        }

        &.h-40 {
            div[class$='control'] {
                height: 40px !important;
                min-height: 40px !important;
            }
        }

        div[class$='menu'],
        div[class$='control'],
        div[class$='singleValue'],
        div[class$='Input'] {
            font-size: 14px;
        }

        div[class$='multiValue'] {
            background: var(--dark-surface-base) !important;
        }

        div[class$='menu'] {
            cursor: pointer;
            background: var(--dark-surface-base) !important;

            div[class$='option'] {
                background-color: var(--dark-surface-base);
                color: white;
            }

            div[class$='option'].focused {
                background-color: var(--dark-surface-900);
            }

            div[class$='option'].selected {
                background-color: var(--dark-surface-850);
            }
        }

        &.checkbox-multi {
            div[class$='menu'] {
                background-color: var(--dark-surface-pure) !important;

                >div {
                    padding: 0 4px 4px 4px;

                    div {
                        padding: 0;
                        border-radius: 3px;
                    }
                }

                div[class$='option'] {
                    background-color: var(--dark-surface-pure) !important;
                }
            }
        }

        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    .v1select.h-fit div[class$='control'] {
        height: fit-content !important;
    }

    .forum-select {
        span[class$='indicatorSeparator'] {
            display: none;
        }

        div[class$='control'] {
            background: var(--base-100) !important;
            outline-width: 0 !important;
            border: 1px solid transparent !important;
            height: 40px !important;
            overflow-y: scroll;

            * {
                color: var(--content-subtle);
            }
        }

        &.surface-850 {
            div[class$='control'] {
                background: var(--base-color) !important;
            }
        }

        &.border-right-0 {
            div[class$='control'] {
                background: var(--base-color) !important;
                border-right: 0px solid transparent !important;
                border-top-right-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
            }
        }

        &.h-auto {
            div[class$='control'] {
                height: fit-content !important;
            }
        }

        &.h-32 {
            div[class$='control'] {
                height: 32px !important;
                min-height: 32px !important;

                div[class$='ValueContainer'],
                >div:nth-child(1) {
                    min-height: 32px;
                    padding: 1.5px 8px 4px 4px;
                }

                div[class$='IndicatorsContainer'],
                >div:nth-child(2) {
                    min-height: 30px;
                    height: 30px;

                    div[class$='indicatorContainer'] {
                        padding: 4px;
                    }
                }
            }
        }

        &.h-40 {
            div[class$='control'] {
                height: 40px !important;
                min-height: 40px !important;
            }
        }

        div[class$='menu'],
        div[class$='control'],
        div[class$='singleValue'],
        div[class$='Input'] {
            font-size: 14px;
        }

        div[class$='multiValue'] {
            background: var(--base-color) !important;
        }

        div[class$='menu'] {
            cursor: pointer;
            background: var(--base-200) !important;
            z-index: 10000;

            div[class$='option'] {
                background-color: var(--base-200);
                color: var(--content-subtle);
            }

            div[class$='option'].focused {
                background-color: var(--base-100);
            }

            div[class$='option'].selected {
                background-color: var(--base-200);
            }
        }

        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
        }

        &.tags-select {
            max-width: 350px;
        }

        &.category-select {
            width: 200px;
        }

    }

    .inactiveSVG {

        svg,
        path,
        defs {
            stroke: var(--dark-text-400) !important;
        }
    }

    .activeSVG {

        svg,
        path,
        defs {
            stroke: var(--dark-text-200) !important;
        }
    }

    .v1-border-2 {
        border: 0.5px solid #464646 !important;
    }

    .boxShadow1 {
        box-shadow: 0px 1px 1px rgba(18, 18, 18, 0.3);
    }

    .violet-background {
        background: #1C172B;
    }

    .violet-11 {
        color: #9E8CFC;
    }

    .border-dashed {
        border-style: dashed !important;
    }

    .overflow-scroll-y {
        overflow-y: scroll;
    }

    .overflow-scroll-x {
        overflow-x: scroll;
    }

    .noscrollbar {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .searchBar {
        input {
            padding-right: 38px;
        }

        .searchIcon {
            margin-left: -31px;
        }
    }

    .text-primary-color {
        color: var(--primary-color);
    }

    .text-primary-subtle {
        color: var(--primary-subtle);
    }

    .text-secondary-color {
        color: var(--secondary-color);
    }

    .text-secondary-100 {
        color: var(--secondary-100);
    }

    .text-secondary-200 {
        color: var(--secondary-200);
    }

    .text-base-color {
        color: var(--base-color);
    }

    .text-base-100 {
        color: var(--base-100);
    }

    .text-base-200 {
        color: var(--base-200);
    }

    .text-content-color {
        color: var(--content-color);
    }

    .text-content-subtle {
        color: var(--content-subtle);
    }

    .text-accent-color {
        color: var(--accent-color);
    }

    .bg-primary-color {
        background: var(--primary-color);
    }

    .bg-primary-subtle {
        background: var(--primary-subtle) !important;
    }

    .bg-secondary-color {
        background: var(--secondary-color);
    }

    .bg-secondary-100 {
        background: var(--secondary-100);
    }

    .bg-secondary-200 {
        background: var(--secondary-200);
    }

    .bg-base-color {
        background: var(--base-color);
    }

    .bg-base-100 {
        background: var(--base-100);
    }

    .bg-base-200 {
        background: var(--base-200);
    }

    .bg-content-color {
        background: var(--content-color);
    }

    .bg-content-subtle {
        background: var(--content-subtle);
    }

    .bg-accent-color {
        background: var(--accent-color);
    }

    .bg-currentBackground {
        background: var(--currentBackground);
    }

    .colorGradientfor {
        width: 36px;
        height: 36px;
        border-radius: 60px;
        border: 1px solid #292A2F;

        &.Purple.Magic {
            background: linear-gradient(99.34deg, rgba(151, 74, 237, 0.05) 3.22%, rgba(151, 74, 237, 1) 100%);
        }

        &.Coffee {
            background: linear-gradient(99.34deg, rgba(219, 146, 75, 0.05) 3.22%, rgba(219, 146, 75, 1) 100%);
        }

        &.Lemonade {
            background: linear-gradient(99.34deg, rgba(81, 153, 3, 0.05) 3.22%, rgba(81, 153, 3, 1) 100%);
        }

        &.Lagoon {
            background: linear-gradient(99.34deg, rgba(101,195,200,0.05) 3.22%, rgba(101,195,200,1) 100%);
        }
        &.Cupcake {
            background: linear-gradient(99.34deg, rgba(101, 195, 200, 0.05) 3.22%, rgba(101, 195, 200, 1) 100%);
        }

        &.Winter {
            background: linear-gradient(99.34deg, rgba(4, 122, 255, 0.05) 3.22%, rgba(4, 122, 255, 1) 100%);
        }

        &.Crimson {
            background: linear-gradient(99deg, #7E0122 3.22%, #DB4B71 100%);
        }

        &.Neutral {
            background: linear-gradient(99deg, #02245F 3.22%, #42526D 100%);
        }
    }

    .global-status-tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        gap: 2px;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 500;

        &.native {
            background-color: var(--dark-colors-grass-02);

            .status-text {
                color: var(--dark-colors-grass-11);
            }
        }

        &.itorix {
            background-color: var(--dark-colors-amber-02);

            .status-text {
                color: var(--dark-colors-amber-11);
                ;
            }
        }

        &.other {
            background-color: var(--dark-colors-red-02);

            .status-text {
                color: var(--dark-colors-red-11);
            }
        }
    }

    .navbarBtnPrimary {
        background: var(--primary-color);
        padding: 8.30433px 12.4565px;
        border-radius: 9px;
        color: var(--base-color);
        font-weight: 600;
        font-family: 'Jost';
        font-style: normal;
        border: 0px !important;
    }

    .navbarBtnSecondary {
        background: var(--secondary-color);
        padding: 8.30433px 12.4565px;
        border-radius: 9px;
        color: var(--base-color);
        font-weight: 600;
        font-family: 'Jost';
        font-style: normal;
        border: 0px !important;
    }

    @media screen and (min-width: 1541px) {
        .container {
            max-width: 1480px;
        }
    }

    @media screen and (max-width: 1540px) and (min-width: 1341px) {
        .container {
            max-width: 1310px;
        }
    }

    .secondaryCTAButton {
        cursor: pointer;
        font-size: 16px;
        color: var(--content-subtle);
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border: 1px solid var(--content-subtle);
        padding: 11px 24px;
        border-radius: 8px;
        font-weight: 500;
        border-radius: 4px;


        &.darkVersion{
            background: var(--content-color);
            color: var(--base-color) !important;
        }
    }

    .primaryCTAButton {
        cursor: pointer;
        font-size: 16px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        padding: 11px 24px;
        border: 1px solid var(--primary-color);
        border-radius: 12px;
        font-weight: 500;
        background: var(--primary-color);
        color: var(--base-color);
        border-radius: 4px;
    }

    .gray-500 {
        color: #667085;
    }

    .eachLandingCard {
        box-shadow: 0px 0px 1.16519px rgba(44, 47, 70, 0.2);
        border-radius: 8.61487px;
        border: 0.873896px solid var(--secondary-200);
        background: var(--base-color);
        padding: 18.6431px;
        max-width: 225px;

        &.Main {
            padding: 32px 48px !important;
            max-width: 31% !important;
        }
    }

    .customHero1 {
        padding: 64px 0px 96px;
    }

    .darkModeToggle {
        label {
            margin-top: 0px;
            position: relative;
            width: 72px;
            height: 32px;
            display: block;
            background: var(--secondary-100);
            // background: #362B3B;
            border-radius: 100px;
            cursor: pointer;
            box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.3), inset 0px -5px 15px rgba(255, 255, 255, 0.3);
        }

        label:after {
            content: '';
            position: absolute;
            height: 28px;
            width: 28px;
            background: white;
            border-radius: 100px;
            top: 2px;
            left: 2px;
            transition: 0.5s;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        }

        input:checked~label:after {
            left: 68px;
            transform: translateX(-100%);
            background: white
        }

        input:checked~label {
            background: var(--secondary-100);
            // background: #F9F2FD;
        }

        .background {
            position: absolute;
            width: 100%;
            background: #fff;
            z-index: -1;
            transition: 0.5s;
        }

        input:checked+label+.background {
            background: var(--secondary-100);
        }

        input {
            display: none;
        }

        .sunIcon {
            position: absolute;
            z-index: 4;
            left: 45px;
            top: 5.5px;
        }

        .moonIcon {
            position: absolute;
            z-index: 4;
            left: 5px;
            top: 6px;
        }
    }

    .eyeIconPass {
        position: absolute;
        bottom: 9.5px;
        right: 12px;
    }


    .switch input {
        position: absolute;
        opacity: 0;
    }

    .switch {
        display: flex;
        align-items: center;
        font-size: 20px;
        height: 20px;
        width: 32px;
        background: #bdb9a6;
        border-radius: 0.8em;
        background: #000000;
    }

    .switch.blue-varient {
        background: var(--dark-surface-800);
    }

    .switch div {
        height: 16px;
        width: 16px;
        border-radius: 0.8em;
        background: #fff;
        box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        transition: all 300ms;
        cursor: pointer;

    }

    .switch input:checked+div {
        background: #369EFF !important;
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    .switch.blue-varient input:checked+div {
        background: #369EFF !important;
        -webkit-transform: translate3d(100%, 0, 0);
        -moz-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }

    .border-dashed {
        border: 1px dashed #8F8F8F;
    }

    .theme-data-type-chip {
        padding: 0 7px;
        height: 18px;
        font-size: 12px;
        border-radius: 2px;
        background: var(--base-200);
        font-family: monospace;
    }

    .hover-underline-bottom:hover{
        border-bottom: 0.5px solid var(--primary-color);
    }
    .hover-underline-bottom-red:hover{
        border-bottom: 0.5px solid var(--dark-colors-red-09);
    }
}


.not-allowed {
    cursor: not-allowed !important;
    *{ cursor: not-allowed !important;}
}

.userTableWrapper {
    .tab-content {
        height: calc(100% - 90px) !important;
    }
}

.v1--styles .mainSelect {
    .v1select div[class$=control] {
        background: var(--base-color) !important;
        border: 1px solid var(--base-200) !important;
        box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.08), inset 1px 1px 2px rgba(0, 0, 0, 0.08) !important;
        border-radius: 4px !important;

        // &::placeholder{
        //     opacity: .7;
        //     font-size: .8rem !important;
        //     color: var(--content-subtle) !important;
        // }
    }

    .v1select div[class$=menu] div[class$=option] {
        background: var(--base-color) !important;
        color: var(--content-subtle) !important;
        cursor: pointer;

        &:hover {
            background: var(--base-100) !important;
        }

        .tag-product-bundle-option {
            display: flex;
            padding: 2px 8px;
            justify-content: center;
            align-items: center;
            gap: 45px;
            border-radius: 38px;
            background: var(--secondary-color) !important;
          
            color: var(--base-color) !important;
            font-size: 12px;
            font-weight: 400;
        }
    }


    .v1select div[class$=menu] {
        background: var(--base-color) !important;
    }

    .v1select div[class$=control] * {
        font-size: .8rem !important;
        color: var(--content-subtle) !important;
    }
}

.v1--styles.theme-modal {
    --bs-modal-bg: var(--currentBackground);
    --bs-modal-color: var(--content-color);
}


.theme--dark {
    .v1--styles {
        .strokes-800-border{
            border: 1px solid #CAD0DB;
            &.v2{
                border: 0.5px solid #CAD0DB;
            }
        }
        .surface-consumer-600{
            background: #31343C;
        }
        .surface-consumer-700{
            background: #363943;
        }
        .surface-consumer-800{
            background: #3F434E;
        }
        .surface-consumer-850{
            background: #2F3135;
        }
        .surface-consumer-875{
            background: #292A2F;
        }
        .surface-consumer-900{
            background: #242529;
        }
        .surface-consumer-pure{
            background: #000000;
        }
        .surface-consumer-base{
            background: #1D1E23;
        }
        .text-consumer-400{
            color: #C2C2C2;
        }
        .text-consumer-300{
            color: #E4E4E4;
        }
        .text-consumer-200{
            color: #F1F1F1;
        }
        .text-consumer-100{
            color: #F6F6F6;
        }
        .custom-bordr-1{
            border: 1px solid #CAD0DB;
        }
        .custom-bordr-2{
            border: 0.5px solid var(--dark-gray-subtext);
        }
        .grey-subtext{
            color: var(--dark-gray-subtext);
        }
        .grey-subtext-subtle{
            color: var(--dark-gray-subtext-subtle);
        }

        .bg-theme-surface-875 {
            background: var(--dark-surface-875);
        }

        .bg-theme-surface-900 {
            background: var(--dark-surface-900);
        }

        .bg-theme-surface-base {
            background: var(--dark-surface-base);
        }

        .api-method-tag {
            &.post {
                background-color: var(--dark-colors-grass-06);
            }

            &.get {
                background-color: var(--dark-colors-blue-05);
            }

            &.delete {
                background-color: var(--dark-colors-red-05);
            }

            &.put {
                background-color: var(--dark-colors-amber-05);
            }

            &.options {
                background-color: var(--dark-colors-violet-05);
            }

            &.patch {
                background-color: var(--dark-colors-purple-05);
            }

            &.trace {
                background-color: var(--dark-colors-orange-05);
            }

            &.head {
                background-color: var(--dark-colors-orange-05);
            }
        }

        .status-color-group {
            ._1XX {
                color: var(--dark-colors-blue-11);
            }

            ._2XX {
                color: var(--dark-colors-grass-11);
            }

            ._3XX {
                color: var(--dark-colors-amber-11);
            }

            ._4XX,
            ._5XX {
                color: var(--dark-colors-red-11);
            }
        }


        .status-group-chip {
            &._1XX {
                background: var(--dark-colors-blue-06);
            }

            &._2XX {
                background: var(--dark-colors-grass-06);
            }

            &._3XX {
                background: var(--dark-colors-amber-06);
            }

            &._4XX,
            &._5XX {
                background: var(--dark-colors-red-06);
            }
        }
    }
}

.myProgressBar {
    height: 8px;
    background: #E4E4E4;
    border-radius: 4px;

    &.v2{
        .filledProgress{
            background: var(--primary-color);
        }
    }

    .filledProgress {
        background: #6E56CF;
        border-radius: 4px;
        height: 8px;
    }
}

.v1-border-bottom-custom {
    border-bottom: 1px solid #2F3135;
}

.amber-11 {
    color: #f1a10d !important;
}


.global-status-tag.approved,
.global-status-tag.active,
.global-status-tag.Active,
.global-status-tag.deployed {
    background-color: #0f1e13;

    .status-text {
        color: #63c174;
        ;
    }
}

.global-status-tag.review {
    background-color: #271700;

    .status-text {
        color: #f1a10d;
        ;
    }
}

.global-status-tag.rejected,
.global-status-tag.revoke,
.global-status-tag.revoked,
.global-status-tag.retired {
    background-color: #291415;

    .status-text {
        color: #ff6369;
    }
}

.global-status-tag.pending {
    background: #FFF4D5;

    .status-text {
        color: #AD5700;
    }
}
.consumer-package-expired {
    background: #FFF8F8;
    border: 1px solid var(--dark-colors-red-09);
    padding: 2px 10px 2px 10px;
    border-radius: 16px;

    .status-text {
        color: var(--dark-colors-red-09);;
    }
}

.editor-bg-transparent {
    .monaco-editor {
        background-color: transparent;

        .overflow-guard {
            background-color: transparent;

            .margin {
                background-color: transparent;
            }

            .monaco-scrollable-element {
                background-color: transparent;

                .monaco-editor-background {
                    background-color: transparent;
                }
            }
        }
    }
}

.editor-no-scroll{
    .monaco-editor .overflow-guard .monaco-scrollable-element canvas{
        display: none !important;
    }
}

.my-table{
    thead{
        th>div{
            height: 28px;
            background: var(--dark-surface-875);
            padding-top: 4px;
            margin-bottom: 8px;
        }
        th:first-child{
            >div{
                margin-left: 8px;
            }
        }
        th:last-child{
            >div{
                margin-right: 8px;
            }
        }
    }

    tbody{
        tr{
            border-bottom: 1px solid var(--dark-surface-800);
            margin-left: 16px;
        }

        tr:hover{
            background-color: var(--dark-surface-875);
        }
    }
}

.theme-search-bar{
    border-radius: 8px;
    border: 1px solid var(--base-100);
    background: var(--base-color);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.theme--dark{
    .theme-search-bar{
        background-color: var(--base-100);
    }
}

.reference-list-v3 {
    border-radius: 38px;
    padding: 1px 12px 1px;
    font-size: 10px;
    text-align: center;
    border: solid 1px #6A40BF;
    background-color: #301A3A;
}

.reference-list-v2 {
    border-radius: 38px;
    border: solid 1px #005D85;
    background-color: #082636;
    padding: 1px 12px 1px;
    font-size: 10px;
    text-align: center;
}

.apiReferenceCard{
    width: 100%;
    height: 160px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--secondary-200);
    background: var(--base-color);
    box-shadow: 0px 4px 12px 6px rgba(170, 170, 170, 0.08);
    width: 360px;
}

.theme--light{
    .v1--styles{
        .strokes-800-border{
            border: 1px solid #464747;
            &.v2{
                border: 0.5px solid #464747;
            }
        }
        .surface-consumer-600{
            background: #DDE6F0;
        }
        .surface-consumer-700{
            background: #D8DEEB;
        }
        .surface-consumer-800{
            background: #CAD0DB;
        }
        .surface-consumer-850{
            background: #FCFCFD;
        }
        .surface-consumer-875{
            background: #FAFBFE;
        }
        .surface-consumer-900{
            background: #FFFFFF;
        }
        .surface-consumer-pure{
            background: #DADEEA;
        }
        .surface-consumer-base{
            background: #EDF0F7;
        }
        .text-consumer-400{
            color: #666666;
        }
        .text-consumer-300{
            color: #464646;
        }
        .text-consumer-200{
            color: #323232;
        }
        .text-consumer-100{
            color: #333333;
        }
        .custom-bordr-1{
            border: 1px solid #CAD0DB;
        }
        .custom-bordr-2{
            border: 0.5px solid var(--light-gray-subtext);
        }
        .grey-subtext{
            color: var(--light-gray-subtext);
        }
        .grey-subtext-subtle{
            color: var(--light-gray-subtext-subtle);
        }
    }
    
    .apiReferenceCard{
        .reference-list-v2 {
            border: solid 1px #4071bf;
            background-color: rgba(137, 182, 255, 0.2);
        }
          
        .reference-list-v3 {
            border: solid 1px #6a40bf;
            background-color: rgba(176, 137, 255, 0.2);
        }
    }

    .border-strokes-800 {
        border-color: var(--light-strokes-consumer-800) !important;
    }

    .border-strokes-600 {
        border-color: var(--light-strokes-consumer-600) !important;
    }
}


.v1-accordion{
    *{border: none !important;}
    .accordion-body{background: #292A2F;}
    .accordion-item, .accordion-header{background: #2F3135; border: none;}
    .accordion-button{background-color: transparent; border: none ;
     &::after{display: none;}}
    .accordion-collapse.collapse.show{border: none;}
    .accordion-button:not(.collapsed){
        box-shadow: none;
    }.accordion-body{padding: 0px;}
    .accordion-button{
        .folder-close{display: none;}
        .folder-open{display: block;}
        .folder-hover{display: none;}
        &.collapsed{
            .folder-open{display: none;}
            .folder-close{display: block;}
            .folder-hover{display: none;}
        }
        // &:hover{
        //     .folder-hover{display: block;}
        //     .folder-close{display: none;}
        //     .folder-open{display: none;}

        //     &.collapsed{
        //         .folder-open{display: none;}
        //         .folder-close{display: none;}
        //         .folder-hover{display: block;}
        //     }
        // }
    }
}

.justify-content-between .accordion-button{
    justify-content: space-between;
}

.dfaic{
    display: flex;
    align-items: center;
}


.accordion-button{
    .collapseIcon{
        margin-top: 10px;
        margin-right: 6px;
        transform: rotate(90deg);
    }
}
.accordion-button.collapsed{
    .collapseIcon{
        margin-top: -8px;
        transform: rotate(270deg);
    }
}

.theme--dark{
    .d2h-file-header, .d2h-tag{
        background-color: var(--base-100) !important;
    }
}

.wrapDropdownOptions{
    white-space: break-spaces;
}

.titleTagPartner{
    width: fit-content;
    padding: 4px 30px;
    background: var(--primary-color);

    text-align: center;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 100px;
    border-top-left-radius: 2px;
}

.dropdown-toggle.nav-link{
    font-size: 15px !important;
}

.userImageCards{
    padding-left: 40px;
    .imgCard{
        margin-left: -15px;
        height: 52px;
        width: 52px;
        border-radius: 100%;
        border: 1px solid var(--base-color);
        z-index: 2;
        overflow: hidden;

        img{
            object-fit: cover;
        }

        &:hover{
            cursor: pointer;
            z-index: 3;
        }
    }
}

.theme--dark {
    .v2-text-GET{color: #96C7F2;}
    .v2-status-code.GET {
        background-color: #0f3058;

        &.bright {
            background-color: #0954a5;
        }
    }
    .v2-text-PUT{color: #F3BA63;}
    .v2-status-code.PUT {
        background-color: #4a2900;

        &.bright {
            background-color: #824e00;
        }
    }
    .v2-text-DELETE{color: #F3AEAF;}
    .v2-status-code.DELETE {
        background-color: #541b1f;

        &.bright {
            background-color: #aa2429;
        }
    }
    .v2-text-POST{color: #97CF9C;}
    .v2-status-code.POST {
        background-color: #193921;

        &.bright {
            background-color: #2f6e3b;
        }
    }
    .v2-text-OPTIONS{color: #79CFEA;}
    .v2-status-code.OPTIONS {
        background-color: #08354c;

        &.bright {
            background-color: #005d85;
        }
    }
    .v2-text-HEAD{color: #D3B4ED;}
    .v2-status-code.HEAD {
        background-color: #432155;

        &.bright {
            background-color: #7938b2;
        }
    }
    .v2-text-PATCH{color: #DEFCF7;}
    .v2-status-code.PATCH {
        background-color: #275f55;

        &.bright {
            background-color: #347f71;
        }
    }
    .v2-text-NEW{color: #79CFEA;}
    .v2-status-code.NEW {
        background-color: #08354c;

        &.bright {
            background-color: #005d85;
        }
    }
    .v2-text-TRACE{color: #FFB381;}
    .v2-status-code.TRACE {
        background-color: #4f2305;

        &.bright {
            background-color: #943e00;
        }
    }
}

.v2-dark-text-GET{color: #96C7F2;}
.v2-dark-status-code.GET {
    background-color: #0f3058;

    &.bright {
        background-color: #0954a5;
    }
}
.v2-dark-text-PUT{color: #F3BA63;}
.v2-dark-status-code.PUT {
    background-color: #4a2900;

    &.bright {
        background-color: #824e00;
    }
}
.v2-dark-text-DELETE{color: #F3AEAF;}
.v2-dark-status-code.DELETE {
    background-color: #541b1f;

    &.bright {
        background-color: #aa2429;
    }
}
.v2-dark-text-POST{color: #97CF9C;}
.v2-dark-status-code.POST {
    background-color: #193921;

    &.bright {
        background-color: #2f6e3b;
    }
}
.v2-dark-text-OPTIONS{color: #79CFEA;}
.v2-dark-status-code.OPTIONS {
    background-color: #08354c;

    &.bright {
        background-color: #005d85;
    }
}
.v2-dark-text-HEAD{color: #D3B4ED;}
.v2-dark-status-code.HEAD {
    background-color: #432155;

    &.bright {
        background-color: #7938b2;
    }
}
.v2-dark-text-PATCH{color: #DEFCF7;}
.v2-dark-status-code.PATCH {
    background-color: #275f55;

    &.bright {
        background-color: #347f71;
    }
}
.v2-dark-text-NEW{color: #79CFEA;}
.v2-dark-status-code.NEW {
    background-color: #08354c;

    &.bright {
        background-color: #005d85;
    }
}
.v2-dark-text-TRACE{color: #FFB381;}
.v2-dark-status-code.TRACE {
    background-color: #4f2305;

    &.bright {
        background-color: #943e00;
    }
}

.theme--light {
    .v2-text-GET{color: #006ADC;}
    .v2-status-code.GET {
        background-color: #cee7fe;

        &.bright {
            background-color: #5eb0ef;
        }
    }
    .v2-text-PUT{color: #AD5700;}
    .v2-status-code.PUT {
        background-color: #ffe3a2;

        &.bright {
            background-color: #ee9d2b;
        }
    }
    .v2-text-DELETE{color: #CD2B31;}
    .v2-status-code.DELETE {
        background-color: #fdd8d8;

        &.bright {
            background-color: #eb9091;
        }
    }
    .v2-text-POST{color: #297C3B;}
    .v2-status-code.POST {
        background-color: #ceebcf;

        &.bright {
            background-color: #65ba75;
        }
    }
    .v2-text-OPTIONS{color: #0078A1;}
    .v2-status-code.OPTIONS {
        background-color: #c1ecf9;

        &.bright {
            background-color: #2ebde5;
        }
    }
    .v2-text-HEAD{color: #793AAF;}
    .v2-status-code.HEAD {
        background-color: #eddbf9;

        &.bright {
            background-color: #be93e4;
        }
    }
    .v2-text-PATCH{color: #299984;}
    .v2-status-code.PATCH {
        background-color: #defcf7;

        &.bright {
            background-color: #2ee5c3;
        }
    }
    .v2-text-NEW{color: #0078A1;}
    .v2-status-code.NEW {
        background-color: #c1ecf9;

        &.bright {
            background-color: #2ebde5;
        }
    }
    .v2-text-TRACE{color: #BD4B00;}
    .v2-status-code.TRACE {
        background-color: #ffdcc3;

        &.bright {
            background-color: #fa934e;
        }
    }
}

.cursor-disabled{
    cursor: not-allowed;
}

.custom-modal-v1 .homeModalForLghtMode .modal-footer{
    background-color: var(--base-color) !important;
}

.theme--dark{
    .custom-border-bottom-1{
        border-bottom: 0.682px solid #31343C;
    }
}
.theme--light{
    .custom-border-bottom-1{
        border-bottom: 0.682px solid #DDE6F0;
    }
}

.primaryTabSection{
    height: 360px;
    background: linear-gradient(99.34deg, #CE9FFC 3.22%, #7367F0 100%);

    *{
        font-family: 'Jost';
    }
}

.secondaryTabSection{
    height: 190px;
    background: linear-gradient(99.34deg, #4F3DC2 3.22%, #66A6FF 100%);

    *{
        font-family: 'Jost';
    }
}

.exploreAllCategories{
    height: 150px;
    background: linear-gradient(295deg, #5C18E4 20.9%, #8852F5 79.91%);
}

.viewallButton{
    border-radius: var(--radius-md, 8px);
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(255, 255, 255, 0.30);
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.06);
}

.tertiaryTab{
    height: 150px;
    background: radial-gradient(51.85% 97.03% at 44.35% 100%, #B2F6CB 0%, #268EC0 100%);

    .font-jost{
        font-family: 'Jost';
    }
}

.text-clip-gradient{
    text-shadow: 0px 4px 6px rgba(16, 24, 40, 0.03), 0px 12px 16px rgba(16, 24, 40, 0.15);
    background: linear-gradient(179deg, #FFF 14.15%, rgba(255, 255, 255, 0.70) 105.36%);
    background-clip: text;
    line-height: 120%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.border-custom-600{
    border: 1px solid #DDE6F0;
}
.border-custom-top-600{
    border-top: 1px solid #DDE6F0 !important;
}

.theme--dark{
    .border-custom-600{
        border: 1px solid #353638;
    }
    .border-custom-top-600{
        border-top: 1px solid #353638 !important;
    }
}

.show-on-hover{
    .hoverItem{
        display: none !important;
    }
}
.show-on-hover:hover{
    .hoverItem{
        display: flex !important;
    }
}

.time-wizard-overlay {
    height: 40px;
}

.timeline-wizard {
    margin: 0px 60px;
    height: 2px;
    border: 1px dashed var(--dark-surface-850);

    &.custom {
        border: 1px dashed var(--dark-surface-800);
    }
}

.timeline-wizard-progress {
    margin-top: -1px;
    width: 0;
    height: 100%;
    border: 1px dashed var(--dark-colors-grass-09);
    transition: 0.5s ease-in-out;
}

.timeline-wizard-items {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -8px;
    display: flex;
    justify-content: space-between;
}

.timeline-wizard-item {
    position: relative;
}

.timeline-wizard-item::before {
    content: "";
    width: 12px;
    height: 12px;
    // border: 1px solid var(--dark-colors-blue-11);
    border: 1px solid var(--dark-text-400);
    background-color: var(--dark-surface-900);
    display: flex;
    justify-content: center;
    border-radius: 100%;
}
.timeline-wizard.custom {
    .timeline-wizard-item::before {
        background-color: var(--dark-surface-850);
    }
}
.timeline-wizard-item.blue-border::before {
    border: 1px solid var(--dark-colors-blue-11);
}

.timeline-wizard-content {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    color: var(--dark-text-200);
}

.timeline-wizard-item.active::before {
    border: none;
    background-image: url(../../assets/icons/Status.svg);
}

.swagger-accordion{
    .accordion-item{
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    }
    .accordion-header{
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
        button {
            padding: 12px;
        }
    }
    .accordion-button{
        .arrow{
            transition: all 0.3s ease-in-out;
            transform: rotate(90deg);
        }
    }
    .accordion-button.collapsed{
        .arrow{
            transform: rotate(0);
        }
    }
    
}