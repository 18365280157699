html:not(.root-text-sm):not(.root-text-lg):not(.root-text-xl) [data-class="root-text"] {
	font-size: 16px;
}

.root-text-sm {
	font-size: 14px;
}

.root-text-lg {
	font-size: 18px;
}

.root-text-xl {
	font-size: 20px;
}

.mod-bigger-font {
	font-size: 20px;
}